import React from "react";
import clsx from "clsx";
import slugify from "slugify";

import { RichText } from "components";

import GridCard from "./_GridCard";
import LinkCard from "./_LinkCard";

export function ColumnGrid({ columnGridCards, heading, linkCard, subheading }) {
  return (
    <section
      id={heading && slugify(heading)}
      data-slice="ColumnGrid"
      className="pt-24 wrapper default-grid lg:pt-30 gap-y-16 md:gap-y-32"
    >
      <div className="space-y-4 col-span-full">
        {heading && <h2 className="h1 text-violet">{heading}</h2>}
        {subheading && <RichText content={subheading} size="large" />}
      </div>
      <div className="gap-px -mx-6 sm:-mx-fluidMargin col-span-full md:mx-0 default-grid">
        <div
          className={clsx(
            "flex flex-col w-full col-span-full space-y-px ",
            columnGridCards.length === 1 ? "lg:col-span-6" : "lg:col-span-5"
          )}
        >
          {columnGridCards?.map((item, i) => {
            return <GridCard {...item} key={`columnGridCard--${i}`} />;
          })}
        </div>
        <div
          className={clsx(
            "ring-1 ring-mineralGray col-span-full",
            columnGridCards.length === 1 ? "lg:col-span-6" : "lg:col-span-7"
          )}
        >
          <LinkCard {...linkCard} />
        </div>
      </div>
    </section>
  );
}
