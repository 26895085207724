import React, { createContext, useContext, useState } from "react"

export const HeaderContext = createContext(undefined)

export function HeaderContextProvider({ children }) {
  const [headerPosition, setHeaderPosition] = useState("absolute")
  const [headerState, setHeaderState] = useState("top")

  return (
    <HeaderContext.Provider
      value={{
        headerState,
        setHeaderState,
        headerPosition,
        setHeaderPosition,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = () => useContext(HeaderContext)
