import React, { useState } from "react";
import clsx from "clsx";
import { MenuLink } from "@reach/menu-button";

import { Icon } from "../Icon";
import { Link } from "../Link";

function NavItem({ label, href }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <MenuLink
      as={Link}
      href={href}
      className="text-darkNavy dark:text-lightBlue dark:hover:text-lightBlue focus:outline-none hover:text-darkNavy dark:focus:text-violet focus:text-violet hover:bg-transparent focus:bg-transparent p-0"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <span className="lead-bold relative">
        {label.split(" | ")[0]}
        <span
          className="absolute flex items-center justify-center transform translate-x-full"
          style={{
            bottom: "0.375em",
            right: "-0.25em",
            width: "0.375em",
            height: "0.375em",
          }}
        >
          <Icon
            className={clsx(
              "text-violet dark:text-lightBlue absolute transform transition-transform ease-out duration-300",
              isHovering === true && "translate-x-1"
            )}
            symbol="arrow-right"
            size="0.375em"
          />
        </span>
      </span>
      {label.split(" | ")[1] && (
        <div className="body text-mineralGray pt-3">
          {label.split(" | ")[1]}
        </div>
      )}
    </MenuLink>
  );
}

export default NavItem;
