import React from "react";
import { createDomMotionComponent } from "framer-motion";

import { Asset, Link } from "components";

const MotionLink = createDomMotionComponent(Link);

const cardVariants = {
  initial: {
    scale: 1,
    transition: { type: "spring", bounce: 0.25, duration: 1 },
  },
  hover: {
    scale: 1.025,
    transition: { type: "spring", bounce: 0.25, duration: 0.5 },
  },
};

function Card({ heading, image, link }) {
  if (link?.link?.link && link?.label)
    return (
      <li className="relative col-span-full md:col-span-6 ring-1 ring-mineralGray">
        <MotionLink
          href={link.link.link}
          className="flex flex-col justify-between w-full h-full bg-white focus:outline-none focus:text-violet text-darkNavy ring-1 ring-mineralGray focus:ring-violet group"
          initial="initial"
          whileHover="hover"
          variants={cardVariants}
        >
          <div className="flex flex-col justify-between flex-1 p-6 space-y-4 sm:px-fluidMargin sm:py-8 md:px-8">
            <h2 className="max-w-xs h3 text-darkNavy group-focus:text-violet">
              {heading}
            </h2>
            <p className="body">
              <span className="text-link">{link?.label}</span>
            </p>
          </div>
          <div className="aspect-w-6 aspect-h-5">
            <Asset asset={image} style={{ position: "absolute" }} />
          </div>
        </MotionLink>
      </li>
    );
  else return null;
}

export default Card;
