import React from "react";
import slugify from "slugify";
import { BLOCKS } from "@contentful/rich-text-types";

import { Asset, Button, Decorator, RichText } from "components";

import Item from "./_Item";

export function SideBySide({
  content,
  decorators,
  list,
  button,
  heading,
  image,
}) {
  return (
    <section
      id={heading && slugify(heading)}
      data-slice="SideBySide"
      className="relative pt-0 pb-10 wrapper lg:py-30"
    >
      <div className="default-grid gap-y-0">
        {decorators && decorators[0] && (
          <div className="relative h-40 col-span-2 row-span-1 row-start-1 lg:h-auto lg:col-span-1">
            <Decorator
              className="absolute top-0 hidden -translate-x-full lg:block -translate-y-1/3 left-full"
              asset={decorators[0].asset}
              size="large"
            />
            <Decorator
              className="absolute top-0 -translate-x-full lg:hidden left-full"
              asset={decorators[0].asset}
              size="small"
            />
          </div>
        )}
        <div className="self-center row-start-2 space-y-12 col-span-full lg:col-span-5 lg:row-start-1 lg:col-start-2">
          <div className="space-y-14">
            {heading && <h2 className="text-darkNavy h3">{heading}</h2>}
            {content && (
              <RichText
                content={content}
                size="small"
                options={{
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <p
                        className="mt-2 mb-4 body text-mineralGray last:mb-0 first:mt-0"
                        style={{ maxWidth: "33ch" }}
                      >
                        {children}
                      </p>
                    ),
                  },
                }}
              />
            )}
            {list?.length >= 1 && (
              <ol className="pr-10 space-y-8 lg:space-y-14 lg:pr-0">
                {list?.map((item, i) => (
                  <Item {...item} key={`listItem-${i}`} />
                ))}
              </ol>
            )}
          </div>
          {button?.label && button?.link?.link && (
            <Button
              label={button.label}
              href={button.link.link}
              appearance="primary"
            />
          )}
        </div>
        <div className="self-center row-start-3 pt-10 col-span-full lg:col-span-5 lg:pt-0 lg:row-start-1 lg:col-start-8">
          {image && (
            <div className="-mx-6 sm:-mx-fluidMargin md:mx-0">
              <div className="md:aspect-w-5 md:aspect-h-4 aspect-w-1 aspect-h-1">
                <Asset asset={image} style={{ position: "absolute" }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
