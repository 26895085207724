import React from "react";
import { useScrollPercentage } from "react-scroll-percentage";
import clsx from "clsx";

import { Asset, RichText } from "components";

function Item({
  closingImage,
  description,
  image,
  index,
  secondaryImage,
  showcaseItems,
}) {
  const [ref, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0,
    // rootMargin: "-10%",
  });
  //const position = useMotionValue(percentage);

  return (
    <li className="isolate relative flex justify-center w-full" ref={ref}>
      <div
        className={clsx(
          "absolute bottom-0 w-[80vw] z-20 max-w-2xl min-w-[224px] sm:w-[40vw]",
          index % 2 === 1 ? "left-0" : "right-0"
        )}
        style={{
          transform:
            index % 2 === 1
              ? `translate3d(-20%, ${180 - percentage * 145}%, 0)`
              : `translate3d(20%, ${180 - percentage * 145}%, 0)`,
        }}
      >
        <div className="aspect-w-1 aspect-h-1">
          <Asset asset={image} objectFit="contain" />
        </div>
      </div>
      <div
        className={clsx(
          "absolute bottom-0 z-0",
          index % 2 === 1 ? "right-0" : "left-0"
        )}
        style={{
          transform:
            index % 2 === 1
              ? `translate3d(20%, ${130 - percentage * 110}%, 0)`
              : `translate3d(-20%, ${130 - percentage * 110}%, 0)`,
        }}
      >
        <div
          className={clsx(
            "transition-all duration-500 max-w-2xl ease-out w-[50vw] min-w-[144px] sm:w-[17.5vw]",
            Math.abs(percentage) < 0.3 || Math.abs(percentage) > 0.8
              ? "blur-md"
              : "blur-sm"
          )}
        >
          <div className="aspect-w-1 aspect-h-1">
            <Asset asset={secondaryImage} objectFit="contain" />
          </div>
        </div>
      </div>
      {description && (
        <div
          className="default-grid relative z-10"
          style={{
            transform:
              index === showcaseItems.length - 1
                ? ``
                : `translate3d(0, ${percentage * 80}%, 0)`,
          }}
        >
          <RichText
            className={clsx(
              index === 0 || index === showcaseItems.length - 1
                ? "col-span-full lg:col-span-8 lg:col-start-3 md:col-span-10 md:col-start-2"
                : "lg:col-span-6 lg:col-start-4 md:col-span-8 md:col-start-3 col-span-full"
            )}
            content={description}
            size="large"
          />
          {index === showcaseItems.length - 1 && (
            <div className="aspect-w-16 col-span-full md:col-span-10 md:col-start-2 aspect-h-9">
              {closingImage && (
                <Asset
                  asset={closingImage}
                  objectFit="contain"
                  objectPosition="center"
                  style={{ position: "absolute" }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </li>
  );
}

export function ProductShowcase({ closingImage, showcaseItems }) {
  return (
    <section
      data-slice="ProductShowcase"
      className="bg-darkPlum dark overflow-hidden"
      id="showcaseWrapper"
    >
      <div className="relative md:pt-[25vh] pb-8 md:pb-12 text-center wrapper">
        <ul className="space-y-[50vh]">
          {showcaseItems?.map((item, index) => (
            <Item
              {...item}
              index={index}
              closingImage={closingImage}
              showcaseItems={showcaseItems}
              key={`showcaseItem-${index}`}
            />
          ))}
        </ul>
      </div>
    </section>
  );
}
