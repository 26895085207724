import clsx from "clsx"

export const buttonStyles = (size, disabled) =>
  clsx(
    "relative inline-flex flex-shrink-0 items-center select-none rounded-full focus:outline-none",
    size === "large" && "h-15 px-7",
    size === "normal" && "h-12 px-6",
    disabled && "opacity-50 pointer-events-none"
  )

export const backgroundStyles = appearance =>
  clsx(
    "absolute top-0 right-0 bottom-0 left-0 w-full h-full rounded-full overflow-hidden",
    appearance === "primary" && "bg-violet",
    appearance === "secondary" && "border-violet border-2",
    appearance === "tertiary" && "border-white border-2",
    appearance === "accepted" && "bg-green"
  )

export const scrimStyles =
  "absolute top-0 right-0 bottom-0 left-0 w-full h-full rounded-full"

export const labelStyles = appearance =>
  clsx(
    "relative pb-0.5 text-lg font-bold whitespace-nowrap leading-none",
    appearance === "primary" && "text-white",
    appearance === "secondary" && "text-violet",
    appearance === "tertiary" && "text-white",
    appearance === "accepted" && "text-darkPurple"
  )
