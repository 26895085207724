import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Asset, RichText } from "components";

import SocialLinks from "./_SocialLinks";

function ThankYou({ content, decorators, image, secondaryContent }) {
  const data = useStaticQuery(SOCIAL_QUERY);
  const footer = data?.contentfulFooter;

  return (
    <div className="wrapper bg-lightBlue">
      <div className="default-grid gap-y-0">
        <div className="py-10 col-span-full md:col-span-6 space-y-14 md:py-24">
          {content && <RichText content={content} size="large" />}
          <div className="space-y-4">
            <p className="cap-subtitle">Connect with us</p>
            <SocialLinks
              linkedIn={footer?.linkedIn}
              instagram={footer?.instagram}
            />
          </div>
        </div>
        <div className="hidden col-start-1 col-span-full md:block md:col-span-6 md:col-start-8">
          <div className="relative h-full px-6 pt-12 pb-12 -mx-6 bg-lightBlue sm:px-fluidMargin md:pr-0 md:pl-24 xl:-mr-26 sm:-ml-fluidMargin md:ml-0 sm:-mr-fluidMargin md:py-24">
            <div className="aspect-w-3 aspect-h-4">
              <Asset
                asset={{ ...image }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;

const SOCIAL_QUERY = graphql`
  query ThankYou {
    contentfulFooter {
      linkedIn {
        linkedIn
      }
      instagram {
        instagram
      }
    }
  }
`;
