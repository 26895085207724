import React from "react";
import { createDomMotionComponent } from "framer-motion";

import { Link } from "../Link";

import {
  buttonStyles,
  backgroundStyles,
  scrimStyles,
  labelStyles,
} from "./_Styles";
import {
  buttonVariants,
  backgroundVariants,
  scrimVariants,
  labelVariants,
} from "./_Variants";

const MotionLink = createDomMotionComponent(Link);

const motion = {
  div: createDomMotionComponent("div"),
  button: createDomMotionComponent("button"),
};

export function Button({
  label = "Button",
  href,
  onClick,
  appearance = "primary",
  size = "normal",
  disabled,
}) {
  if (href) {
    return (
      <MotionLink
        data-component="Button"
        className={buttonStyles(size, disabled)}
        href={href}
        initial="initial"
        whileHover="hover"
        whileFocus="focus"
        whileTap="tap"
        variants={buttonVariants(appearance)}
        disabled={disabled}
      >
        <motion.div
          data-component="Button.Background"
          className={backgroundStyles(appearance)}
          variants={backgroundVariants(appearance)}
        />
        <motion.div
          data-component="Button.Scrim"
          className={scrimStyles}
          variants={scrimVariants}
        />
        <motion.div
          data-component="Button.Label"
          className={labelStyles(appearance)}
          variants={labelVariants(appearance)}
        >
          {label}
        </motion.div>
      </MotionLink>
    );
  }
  if (onClick) {
    return (
      <motion.button
        data-component="Button"
        className={buttonStyles(size, disabled)}
        onClick={onClick}
        initial="initial"
        whileHover="hover"
        whileFocus="focus"
        whileTap="tap"
        variants={buttonVariants(appearance)}
        disabled={disabled}
      >
        <motion.div
          data-component="Button.Background"
          className={backgroundStyles(appearance)}
          variants={backgroundVariants(appearance)}
        />
        <motion.div
          data-component="Button.Scrim"
          className={scrimStyles}
          variants={scrimVariants}
        />
        <motion.div
          data-component="Button.Label"
          className={labelStyles(appearance)}
          variants={labelVariants(appearance)}
        >
          {label}
        </motion.div>
      </motion.button>
    );
  }
  return null;
}
