import React, { useState } from "react";
import clsx from "clsx";

import { Link } from "../Link";

export function TextLink({ className, href, label, ...rest }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Link
      data-component="TextLink"
      className={clsx(
        "text-link transition-opacity duration-150 ease-out focus:opacity-75",
        isHovering === true && "opacity-75",
        className
      )}
      href={href}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...rest}
    >
      {label}
    </Link>
  );
}
