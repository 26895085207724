import React from "react";
import clsx from "clsx";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import { Asset, Button, RichText } from "components";

function GridCard({ color, image, text }) {
  return (
    <div
      className={clsx(
        "relative flex-1 flex flex-col justify-center items-center ring-mineralGray ring-1",
        color === "Purple" && "bg-lightBlue",
        color === "White" && "bg-white"
      )}
    >
      {image && (
        <Asset
          asset={image}
          objectFit="contain"
          objectPosition="center"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {text && (
        <RichText
          className="relative flex flex-col items-center justify-center flex-1 w-full h-full px-6 py-10 space-y-4 sm:px-fluidMargin md:py-16 md:px-14"
          content={text}
          options={{
            renderNode: {
              [BLOCKS.HEADING_2]: (node, children) => (
                <p className="text-center h3 text-darkNavy">{children}</p>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <p className="text-center h2 text-darkNavy">{children}</p>
              ),
              [BLOCKS.HEADING_4]: (node, children) => (
                <p className="max-w-xs text-center h4 text-darkNavy">
                  {children}
                </p>
              ),
              [BLOCKS.HEADING_5]: (node, children) => (
                <p className="max-w-xs text-center lead-copy text-darkNavy">
                  {children}
                </p>
              ),
              [BLOCKS.HEADING_6]: (node, children) => (
                <p className="max-w-xs text-center cap-subtitle text-mineralGray">
                  {children}
                </p>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="max-w-xs text-center small-copy text-darkNavy">
                  {children}
                </p>
              ),
              [INLINES.HYPERLINK]: (node, children, uri) => (
                <Button
                  href={node.data.uri}
                  appearance="secondary"
                  label={children}
                />
              ),
            },
          }}
        />
      )}
    </div>
  );
}

export default GridCard;
