import React, { useState } from "react";
import clsx from "clsx";

import { Icon } from "components";

function Item({ label, icon, href }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <li>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={clsx(
          "flex items-center justify-center w-11 h-11 transition-colors duration-150 ease-out outline-none focus:text-violet focus:outline-none",
          isHovering === true ? "text-violet" : "text-mineralGray"
        )}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Icon
          className={clsx(
            "transform origin-center duration-150 ease-out transition-transform",
            isHovering === true && "scale-110"
          )}
          symbol={icon}
          size={24}
        />
        <span className="sr-only">{label}</span>
      </a>
    </li>
  );
}

function SocialLinks({ linkedIn, instagram }) {
  return (
    <ul className="flex -m-2 space-x-2">
      {linkedIn?.linkedIn && (
        <Item
          label="LinkedIn"
          icon={["fab", "linkedin"]}
          href={linkedIn.linkedIn}
        />
      )}
      {instagram?.instagram && (
        <Item
          label="Facebook"
          icon={["fab", "instagram"]}
          href={instagram.instagram}
        />
      )}
    </ul>
  );
}

export default SocialLinks;
