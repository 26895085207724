import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDown,
  faArrowRight,
  faBars,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faMinus,
  faPlus,
  faSearchPlus,
  faTimes,
  faGlobeEurope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faLinkedin,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import { Footer, Header } from "components";
import { HeaderContextProvider } from "contexts";
import "styles/index.css";

export default function Layout({ children }) {
  library.add(
    faArrowDown,
    faArrowRight,
    faBars,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faMinus,
    faPlus,
    faSearchPlus,
    faTimes,
    faGlobeEurope,
    faInstagram,
    faTwitter,
    faLinkedin,
    faFacebook
  );

  return (
    <HeaderContextProvider>
      <div className="w-full max-w-full overflow-hidden">
        <Helmet>
          <html lang="en" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, shrink-to-fit=no, viewport-fit=cover"
          />
        </Helmet>
        <Header />
        {children}
        <Footer />
        {/* <DebugGrid /> */}
      </div>
    </HeaderContextProvider>
  );
}

export const fragmentQueries = graphql`
  fragment DecoratorFragment on ContentfulDecorator {
    asset {
      description
      small: gatsbyImageData(
        width: 179
        aspectRatio: 1
        resizingBehavior: PAD
        quality: 100
      )
      large: gatsbyImageData(
        width: 389
        aspectRatio: 1
        resizingBehavior: PAD
        quality: 100
      )
      file {
        contentType
        url
      }
    }
  }
  fragment LinkFragment on ContentfulLink {
    label
    link {
      link
    }
  }
  fragment AssetFragment on ContentfulAsset {
    title
    description
    file {
      contentType
      url
      details {
        image {
          width
          height
        }
      }
    }
  }
`;
