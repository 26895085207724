import React from "react";

import { Decorator, RichText } from "components";

import PseudoBackground from "./_PseudoBackground";
import VarietyCard from "./_VarietyCard";

function KeyLines() {
  return (
    <div className="absolute bottom-0 left-0 hidden h-0 md:block">
      <div className="absolute top-0 left-0 h-px origin-left transform -translate-x-26 w-96 bg-mineralGray" />
      <div className="absolute top-0 left-0 h-px origin-left transform -rotate-90 translate-y-10 w-96 bg-mineralGray" />
    </div>
  );
}

// function Varieties({ products, category, subcategory }) {
export function ProductGrid({ content, decorators, products }) {
  if (products && products[0])
    return (
      <section id="varieties" className="relative wrapper">
        {content && <RichText content={content} />}
        <div className="pb-0 lg:pb-30 default-grid md:gap-y-24 gap-y-10 pt-14">
          <PseudoBackground
            decoratorSlot={
              decorators &&
              decorators[0] && (
                <Decorator
                  className="absolute top-0 right-0 hidden -translate-x-16 lg:block lg:translate-y-8 xl:translate-y-1/4"
                  asset={decorators && decorators[0].asset}
                  size="large"
                />
              )
            }
          />
          <div className="relative row-span-1 row-start-1 pb-12 md:pb-20 lg:pb-26 col-span-full lg:col-span-9 lg:col-start-4">
            {/* <div className="relative top-0 left-0 h-0 md:hidden col-span-full">
              <div className="absolute top-0 left-0 h-px -ml-8 origin-left transform -translate-y-8 w-44 bg-mineralGray" />
            </div> */}
            <div className="absolute top-0 hidden bottom-px lg:block -left-6 -right-6 md:-left-26 md:-right-26">
              <KeyLines />
            </div>
            <div>
              <ul className="relative grid-cols-2 default-grid lg:grid-cols-3">
                {products
                  // ?.sort((a, b) => {
                  //   return a.name > b.name ? 1 : -1
                  // })
                  ?.map((variety, i) => {
                    return (
                      <VarietyCard
                        {...variety}
                        index={i}
                        key={`variety-${i}`}
                      />
                    );
                  })}
                {/* {(products?.length === 4 || products?.length === 5) && (
                  <CTACard
                    {...category}
                    size={products?.length === 4 ? "Large" : "Small"}
                  />
                )} */}
              </ul>
            </div>
          </div>
        </div>
        {decorators && decorators[1] && (
          <Decorator
            className="absolute bottom-0 right-0 hidden lg:block -translate-x-1/3 -translate-y-0"
            asset={decorators && decorators[1].asset}
            size="small"
          />
        )}
      </section>
    );
  else return null;
}

// export default Varieties

// <section
//   data-slice="ProductGrid"
//   className="pt-24 wrapper default-grid lg:pt-30 gap-y-10 md:gap-y-14"
// >
//   {content && <RichText content={content} />}

//   {decorators && decorators[0] && (
//     <Decorator
//       className="absolute left-0 transform -translate-x-1/2 top-7"
//       asset={decorators && decorators[0].asset}
//       size="small"
//     />
//   )}
//   {decorators && decorators[1] && (
//     <Decorator
//       className="absolute bottom-0 right-0"
//       asset={decorators && decorators[1].asset}
//       size="large"
//     />
//   )}
//   {products &&
//     products[0].id &&
//     products.map((item, index) => <p>{item?.id}</p>)}
// </section>
