import React from "react";
import slugify from "slugify";
import "@reach/accordion/styles.css";

import Card from "./_Card";

export function CertificationList({ certifications, heading }) {
  return (
    <section
      data-slice="LocationList"
      id={heading && slugify(heading)}
      className="relative pt-0 pb-24 md:pt-12 wrapper default-grid md:pb-48 gap-y-0"
    >
      <div className="space-y-8 col-span-full md:space-y-10">
        <div>
          {heading && (
            <h1 className="text-center h1 text-darkNavy">{heading}</h1>
          )}
        </div>
        <ul className="grid grid-cols-2 gap-8 space-y-8">
          {certifications?.map((item, i) => (
            <Card {...item} key={`certification--${i}`} />
          ))}
        </ul>
      </div>
    </section>
  );
}
