import React from "react";
import clsx from "clsx";

export function Flag({ label = "Flag", appearance = "primary" }) {
  return (
    <div
      data-component="Flag"
      className={clsx(
        "inline-flex items-center h-9 md:h-10 px-3",
        appearance === "primary" && "bg-violet text-white",
        appearance === "secondary" && "bg-white text-violet"
      )}
    >
      <span className="cap-subtitle">{label}</span>
    </div>
  );
}
