import React from "react";

function PseudoBackground({ decoratorSlot }) {
  return (
    <div className="col-span-full relative z-0 flex flex-col h-full row-span-1 row-start-1">
      <div className="default-grid lg:grid-cols-4 relative z-10 grid-cols-2">
        <div className="relative" style={{ paddingBottom: "100%" }}>
          {decoratorSlot}
        </div>
      </div>
      <div className="bg-lightBlue relative flex-1">
        <div className="bg-lightBlue left-1/2 absolute top-0 bottom-0 w-screen h-full transform -translate-x-1/2" />
      </div>
    </div>
  );
}

export default PseudoBackground;
