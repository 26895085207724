import React from "react";
import { useReducedMotion } from "framer-motion";

export const Video = ({ asset, ...rest }) => {
  const shouldReduceMotion = useReducedMotion();

  if (asset?.file?.url) {
    return (
      <video
        data-component="Video"
        preload="none"
        autoPlay={!shouldReduceMotion}
        loop
        muted
        playsInline
        {...rest}
      >
        <source src={asset.file.url} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    );
  } else {
    return null;
  }
};
