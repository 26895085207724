import React, { useState } from "react";
import clsx from "clsx";

import { Icon } from "../Icon";
import { Link } from "../Link";

export function IconButton({
  href,
  label = "Icon Button",
  icon = "arrow-right",
  onClick,
  animate = "right",
}) {
  const [isHovering, setIsHovering] = useState(false);

  const buttonStyles = clsx(
    "inline-flex items-center flex-shrink-0 space-x-2 text-lg font-bold leading-none select-none flex-nowrap whitespace-nowrap text-darkNavy dark:text-lightBlue cursor-pointer focus:outline-none focus:text-violet dark:focus:text-violet"
  );
  const iconStyles = clsx(
    "text-violet dark:text-lightBlue transform ease-out transition-transform duration-150",
    animate === "up" && isHovering === true && "-translate-y-1",
    animate === "right" && isHovering === true && "translate-x-1",
    animate === "down" && isHovering === true && "translate-y-1",
    animate === "left" && isHovering === true && "-translate-x-1"
  );

  if (href) {
    return (
      <Link
        data-component="IconButton"
        className={buttonStyles}
        href={href}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <span>{label}</span>
        <Icon
          aria-hidden
          className={iconStyles}
          symbol={icon}
          size="0.75em"
          style={{ marginTop: "0.25em" }}
        />
      </Link>
    );
  }
  if (onClick) {
    return (
      <button
        data-component="IconButton"
        className={buttonStyles}
        onClick={onClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <span>{label}</span>
        <Icon
          aria-hidden
          className={iconStyles}
          symbol={icon}
          size="0.75em"
          style={{ marginTop: "0.25em" }}
        />
      </button>
    );
  }
  return null;
}
