import React from "react";

import TeamMember from "./_TeamMember";

export function TeamGrid({ heading, teamMembers }) {
  return (
    <section
      data-slice="TeamGrid"
      className="space-y-14 lg:space-y-24 wrapper py-14 lg:pt-30 lg:pb-36"
    >
      {heading && <h2 className="text-center h1 text-darkNavy">{heading}</h2>}
      <ul className="grid-cols-6 default-grid lg:grid-cols-12 gap-y-8 sm:gap-y-14">
        {teamMembers?.map((item, i) => (
          <TeamMember {...item} key={`teamMember--${i}`} />
        ))}
      </ul>
    </section>
  );
}
