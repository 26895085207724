import React from "react";

import GeneralInquiry from "./_GeneralInquiry";
import ThankYou from "./_ThankYou";

export function Form({ form, ...rest }) {
  return (
    <section data-slice="Form" className="pt-10">
      {form === "Contact" && <GeneralInquiry {...rest} />}
      {form === "Thank You" && <ThankYou {...rest} />}
    </section>
  );
}
