import React from "react";

import { Asset, Button, Decorator, IconButton, RichText } from "components";

export function HomeHero({
  heroButtons,
  heroContent,
  heroDecorators,
  heroHeading,
  heroImage,
  heroImageMobile,
}) {
  if (heroHeading)
    return (
      <section className="dark">
        <div className="pt-32 -mt-32 dark:bg-darkPlum">
          <div className="relative wrapper lg:pt-24 pt-14">
            {heroDecorators && heroDecorators[0] && (
              <Decorator
                className="absolute top-0 left-0 hidden -translate-x-4 select-none lg:translate-x-0 lg:block"
                asset={heroDecorators && heroDecorators[0].asset}
                size="small"
              />
            )}
            {heroDecorators && heroDecorators[1] && (
              <Decorator
                className="absolute top-0 right-0 hidden translate-x-4 select-none lg:translate-x-1/2 lg:block"
                asset={heroDecorators[1].asset}
                size="large"
              />
            )}
            <div className="default-grid gap-y-16">
              <div className="relative col-span-full lg:col-span-8 lg:col-start-3">
                <div className="space-y-8 md:space-y-10">
                  {heroHeading && (
                    <h1 className="text-center h1 text-darkPurple dark:text-thistle">
                      {heroHeading}
                    </h1>
                  )}
                  {heroContent && (
                    <RichText
                      className="text-center"
                      content={heroContent}
                      size="large"
                    />
                  )}
                  <div>
                    <ul className="flex flex-wrap items-center justify-center -m-3 md:-m-4 md:flex-nowrap">
                      {heroButtons && heroButtons[0]?.link?.link && (
                        <li className="p-3 md:p-4">
                          <Button
                            label={heroButtons[0]?.label}
                            href={heroButtons[0]?.link?.link}
                          />
                        </li>
                      )}
                      {heroButtons && heroButtons[1]?.link?.link && (
                        <li className="p-3 md:p-4">
                          <IconButton
                            label={heroButtons[1]?.label}
                            href={heroButtons[1]?.link?.link}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden pb-24 md:block">
              <div className="aspect-w-5 aspect-h-2">
                {heroImage && (
                  <Asset
                    asset={heroImage}
                    objectFit="cover"
                    objectPosition="center"
                    style={{ position: "absolute" }}
                  />
                )}
              </div>
            </div>
            <div className="pb-12 md:hidden">
              <div className="aspect-w-5 aspect-h-4">
                {heroImageMobile && (
                  <Asset
                    asset={heroImageMobile}
                    objectFit="contain"
                    objectPosition="center"
                    style={{ position: "absolute" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  else return null;
}
