import React from "react";

import { RichText } from "components";

function ListItem({ content, title }) {
  return (
    <li className="lg:col-span-4 col-span-full sm:col-span-3 space-y-4">
      <div className="space-y-2">
        {title && <h3 className="text-violet h5">{title}</h3>}
      </div>
      {content && <RichText content={content} size="large" />}
    </li>
  );
}

export default ListItem;
