import React, { useEffect } from "react";
import { BLOCKS } from "@contentful/rich-text-types";

import { RichText } from "components";

function GeneralInquiry({ content, secondaryContent }) {
  useEffect(() => {
    let scriptContainer = document.getElementById("scriptId");
    const script = document.createElement("script");

    script.src =
      "https://online3.superoffice.com/Cust27938/CS/scripts/customer.fcgi?action=form&id=F-4POcHgCP";
    script.async = true;

    scriptContainer.appendChild(script);
  }, []);
  return (
    <div className="pb-10 -mt-12 wrapper md:mt-0 md:pb-28">
      <div className="default-grid">
        <div className="col-span-full md:col-span-6">
          <div className="relative h-full px-6 py-10 -mx-6 bg-lightBlue md:px-18 sm:-mx-fluidMargin md:mx-0 md:py-14 sm sm:px-fluidMargin">
            <ul className="space-y-12 md:max-w-sm">
              {content && (
                <li className="space-y-6">
                  <RichText
                    content={content}
                    size="small"
                    options={{
                      renderNode: {
                        [BLOCKS.HEADING_5]: (node, children) => (
                          <h3 className="mt-2 mb-2 lead-copy text-darkNavy last:mb-0 first:mt-0">
                            {children}
                          </h3>
                        ),
                      },
                    }}
                  />
                  <div className="w-full h-px bg-violet" />
                </li>
              )}
              {secondaryContent && (
                <li className="space-y-6">
                  <RichText
                    content={secondaryContent}
                    size="small"
                    options={{
                      renderNode: {
                        [BLOCKS.HEADING_5]: (node, children) => (
                          <h3 className="mt-2 mb-2 lead-copy text-darkNavy last:mb-0 first:mt-0">
                            {children}
                          </h3>
                        ),
                      },
                    }}
                  />
                  <div className="w-full h-px bg-violet" />
                </li>
              )}
            </ul>
            <div className="absolute bottom-0 left-0 hidden w-full md:block">
              <div className="absolute top-0 left-0 w-11/12 h-px transform bg-mineralGray -translate-x-14" />
              <div className="absolute top-0 left-0 w-px transform -translate-y-10 bg-mineralGray h-15" />
            </div>
          </div>
        </div>
        <div className="col-span-full md:col-span-6">
          <div id="scriptId" className="crm-form" />
        </div>
      </div>
    </div>
  );
}

export default GeneralInquiry;
