import React, { useState } from "react";
import clsx from "clsx";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  useDisclosureContext,
} from "@reach/disclosure";
import { createDomMotionComponent } from "framer-motion";
import pluralize from "pluralize";

import { Asset } from "../Asset";
import { Flag } from "../Flag";
import { Icon } from "../Icon";
import { Link } from "../Link";

import { getAllButLastWord, getLastWord } from "utils";

const motion = {
  div: createDomMotionComponent("div"),
};
const MotionDisclosurePanel = createDomMotionComponent(DisclosurePanel);

const containerVariants = {
  open: {
    marginTop: 32,
    marginBottom: 32,
    paddingTop: 24,
    // transition: {
    //   type: "easeOut",
    //   duration: 0.4,
    //   delayChildren: 0.2,
    // },
  },
  closed: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const panelVariants = {
  open: {
    height: "auto",
    transition: {
      type: "easeOut",
      duration: 0.4,
      delayChildren: 0.2,
    },
  },
  closed: {
    height: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const contentVariants = {
  open: {
    opacity: 1,
    pointerEvents: "auto",
    y: 0,
    transition: {
      type: "easeOut",
      duration: 0.3,
    },
  },
  closed: {
    opacity: 0,
    pointerEvents: "none",
    y: 16,
    transition: {
      type: "easeOut",
      duration: 0.2,
    },
  },
};

function SubCard({ category, href, image, title }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Link
      className="block p-0 bg-white hover:bg-white focus:bg-white focus:outline-none"
      href={href}
      style={{ maxWidth: 284 }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="aspect-w-8 aspect-h-7 bg-darkOverlay">
        <Asset
          asset={image}
          objectFit="cover"
          objectPosition="center"
          style={{
            position: "absolute",
          }}
        />
        <div className="flex items-end pl-6">
          <Flag
            label={pluralize.singular(category?.name)}
            appearance="primary"
          />
        </div>
      </div>
      <div className="px-6 py-4 space-y-2 whitespace-normal">
        <p className="lead-bold text-darkNavy">{title}</p>
        <div aria-hidden className="lead-bold">
          <Icon
            className={clsx(
              "text-violet transform transition-transform ease-out duration-300",
              isHovering === true && "translate-x-1"
            )}
            symbol="arrow-right"
            size="0.75em"
          />
        </div>
      </div>
    </Link>
  );
}

function SubItem({ label, href }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div>
      <Link
        href={href}
        className="p-0 text-darkNavy focus:outline-none hover:text-darkNavy focus:text-violet hover:bg-transparent focus:bg-transparent"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="h4">
          {getAllButLastWord(label.split(" | ")[0])}
          <span className="inline-flex items-center flex-shrink-0 whitespace-nowrap">
            {getLastWord(label.split(" | ")[0])}
            <span
              className="flex items-center justify-center"
              style={{
                width: "0.375em",
                height: "0.375em",
                marginTop: "0.25em",
                marginLeft: "0.25em",
              }}
            >
              <Icon
                className={clsx(
                  "text-violet transform transition-transform ease-out duration-300",
                  isHovering === true && "translate-x-1"
                )}
                symbol="arrow-right"
                size="0.5em"
              />
            </span>
          </span>
        </div>
        {label.split(" | ")[1] && (
          <div className="pt-2 body text-mineralGray">
            {label.split(" | ")[1]}
          </div>
        )}
      </Link>
    </div>
  );
}

function SubLabel({ label, href }) {
  return (
    <div>
      <Link
        className="p-0 mt-auto font-bold underline text-violet body hover:bg-transparent focus:outline-none focus:bg-transparent hover:text-violet focus:text-violet"
        href={href}
        style={{
          textDecorationThickness: "2px",
          textUnderlineOffset: "2px",
        }}
      >
        {label}
      </Link>
    </div>
  );
}

function Inner({ featuredContent, links, secondaryLink, title }) {
  const { open } = useDisclosureContext();

  const [isHovering, setIsHovering] = useState(false);

  if (title && links)
    return (
      <motion.div
        className={clsx(
          "sm:-mx-fluidMargin sm:px-fluidMargin px-6 -mx-6",
          open ? "bg-lightBlue" : ""
        )}
        animate={open ? "open" : "closed"}
        initial="closed"
        variants={containerVariants}
        layout
      >
        <DisclosureButton
          className={clsx(
            "w-full text-left h3 text-darkNavy focus:outline-none focus:text-violet",
            open && "text-violet"
          )}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {getAllButLastWord(title)}
          <span className="inline-flex items-center flex-shrink-0 whitespace-nowrap">
            {getLastWord(title)}
            <span
              className="flex items-center justify-center"
              style={{
                width: "0.375em",
                height: "0.375em",
                marginTop: "0.25em",
                marginLeft: "0.375em",
              }}
            >
              <Icon
                className={clsx(
                  "text-violet absolute transform transition-transform ease-out duration-300",
                  isHovering === true && "translate-y-1",
                  open && "rotate-180 translate-y-0"
                )}
                symbol="chevron-down"
                size="0.5em"
              />
            </span>
          </span>
        </DisclosureButton>
        <MotionDisclosurePanel
          className="overflow-hidden focus:outline-none"
          hidden={false}
          aria-hidden={!open}
          animate={open ? "open" : "closed"}
          initial="closed"
          variants={panelVariants}
          layout
        >
          <motion.div
            className="pt-8 pb-8 space-y-14"
            variants={contentVariants}
          >
            <div className="space-y-6">
              <div className="space-y-8">
                {links?.map(({ label, link }, i) => (
                  <SubItem
                    label={label}
                    href={link?.link}
                    key={`subItem-${i}`}
                  />
                ))}
              </div>
              {secondaryLink && (
                <SubLabel
                  label={secondaryLink?.label}
                  href={secondaryLink?.link?.link}
                />
              )}
            </div>
            {featuredContent && (
              <div className="space-y-6">
                <SubCard
                  {...featuredContent}
                  href={
                    "/news/" +
                    (featuredContent?.category?.slug === "/"
                      ? ""
                      : featuredContent?.category?.slug + "/") +
                    featuredContent?.slug +
                    "/"
                  }
                />
                <SubLabel
                  label={
                    "See more " + featuredContent?.category?.name?.toLowerCase()
                  }
                  href={
                    "/news/" +
                    (featuredContent?.category?.slug === "/"
                      ? ""
                      : featuredContent?.category?.slug + "/")
                  }
                />
              </div>
            )}
          </motion.div>
        </MotionDisclosurePanel>
      </motion.div>
    );
  else return null;
}

function Item(props) {
  return (
    <Disclosure>
      <Inner {...props} />
    </Disclosure>
  );
}

export default Item;
