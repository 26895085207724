import React from "react";

import { Image } from "../Image";
import { Video } from "../Video";

// Helper component to take in an asset and create either a file link, video, or image
export function Asset({ asset, ...rest }) {
  if (asset?.file?.contentType.startsWith("video/")) {
    return <Video asset={asset} {...rest} />;
  }
  if (asset?.file?.contentType.startsWith("image/")) {
    return <Image asset={asset} {...rest} />;
  }
  if (
    !asset?.file?.contentType.startsWith("video/") &&
    !asset?.file?.contentType.startsWith("image/")
  ) {
    return (
      <a href={asset?.file?.url} target="_blank" rel="noopener noreferrer">
        {asset?.title}
      </a>
    );
  }
}
