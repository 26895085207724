import React from "react";

import { Asset } from "components";

function Card({ description, icon, name }) {
  if (name && description)
    return (
      <li className="col-span-2 gap-2 space-y-4 md:col-span-1 lg:grid lg:items-center lg:grid-cols-6">
        <div className="lg:col-span-2 w-36">
          <div className="block select-none aspect-w-1 aspect-h-1">
            {icon && (
              <Asset
                asset={icon}
                objectFit="cover"
                objectPosition="center"
                style={{ position: "absolute" }}
              />
            )}
          </div>
        </div>
        <div className="space-y-4 lg:col-span-4">
          <h4 className="h4 text-violet">{name}</h4>
          <p className="body text-mineralGray">{description}</p>
        </div>
      </li>
    );
  else return null;
}

export default Card;
