import React, { useState } from "react";
import clsx from "clsx";

import { Asset, Icon, Link } from "components";

function LinkCard({ heading, image, link }) {
  const [isHovering, setIsHovering] = useState(false);
  if (heading && image && link?.link?.link)
    return (
      <article className="h-full">
        <Link
          href={link.link.link}
          className="flex flex-col h-full text-darkNavy focus:text-violet"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <div className="aspect-w-16 aspect-h-9 bg-mineralGray">
            {image && (
              <Asset
                asset={image}
                objectFit="cover"
                objectPosition="center"
                style={{
                  position: "absolute",
                }}
              />
            )}
          </div>
          <div className="flex flex-col justify-between flex-1 px-6 py-6 space-y-6 bg-white sm:px-fluidMargin md:px-8 md:py-8 md:space-y-8">
            {heading && <p className="text-current h4">{heading}</p>}
            <div
              className={clsx(
                "flex items-center justify-center transform transition-transform ease-out duration-300 w-6 h-6",
                isHovering === true && "translate-x-2"
              )}
            >
              <Icon
                aria-hidden
                className="text-violet"
                symbol="arrow-right"
                size={24}
              />
            </div>
          </div>
        </Link>
      </article>
    );
  else return null;
}

export default LinkCard;
