import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const Image = ({ asset, alt, ...rest }) => {
  const resolvedImage = getImage(asset.gatsbyImageData);
  // return null if resolved image isn't there-- we don't want broken images causes runtime errors.
  if (resolvedImage) {
    return (
      <GatsbyImage
        data-component="Image"
        image={resolvedImage}
        alt={alt ? alt : asset?.description}
        {...rest}
      />
    );
  } else {
    return null;
  }
};
