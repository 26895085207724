import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import clsx from "clsx";
import LazyLoad from "react-lazyload";
import { BLOCKS } from "@contentful/rich-text-types";

import { Asset, RichText, Tooltip } from "components";

export function DetailGrid({
  colorTheme,
  formats,
  formatsTitle,
  items,
  video,
  videoPreview,
  videoText,
}) {
  return (
    <section
      id="details"
      className="relative overflow-hidden ring-1 ring-mineralGray wrapper"
    >
      <div
        className={clsx(
          "absolute top-0 bottom-0 w-6 h-full -left-px ring-mineralGray ring-1 sm:w-fluidMargin xl:w-26",
          colorTheme === "Purple" && "bg-violet",
          colorTheme === "Blue" && "bg-blue",
          colorTheme === "Red" && "bg-red",
          colorTheme === "Yellow" && "bg-gold"
        )}
      />
      <div
        className={clsx(
          "absolute top-0 bottom-0 w-6 h-full -right-px ring-mineralGray ring-1 sm:w-fluidMargin xl:w-26",
          colorTheme === "Purple" && "bg-violet",
          colorTheme === "Blue" && "bg-blue",
          colorTheme === "Red" && "bg-red",
          colorTheme === "Yellow" && "bg-gold"
        )}
      />
      <div className="relative flex flex-col space-y-px lg:flex-row lg:space-y-0 lg:space-x-px">
        <div className="flex flex-col flex-1 space-y-px">
          {items.map((item, index) => (
            <div className="px-6 py-8 -mr-6 space-y-4 bg-white sm:px-fluidMargin sm:-mr-fluidMargin md:mr-0 md:py-16 md:px-16 ring-1 ring-mineralGray">
              <h2 className="text-darkNavy h4">{item.title}</h2>
              <RichText content={item.content} />
            </div>
          ))}
          {/* Color Texture */}
          <div className="relative flex-1 hidden lg:block ring-1 ring-mineralGray md:-ml-fluidMargin xl:-ml-26">
            <div className="relative flex items-center justify-center h-full md:-ml-px">
              <div
                className={clsx(
                  "absolute top-0 bottom-0 left-0 right-0 w-full h-full",
                  colorTheme === "Purple" && "bg-violet",
                  colorTheme === "Blue" && "bg-blue",
                  colorTheme === "Red" && "bg-red",
                  colorTheme === "Yellow" && "bg-gold"
                )}
              >
                {colorTheme === "Purple" && (
                  <StaticImage
                    className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                    src="../../assets/product-categories/jelly@2x.jpg"
                    width={768}
                    placeholder="blurred"
                    alt="Jelly very close"
                    objectFit="cover"
                    objectPosition="center"
                    backgroundColor="#8e426c"
                    style={{ position: "absolute" }}
                  />
                )}
                {colorTheme === "Blue" && (
                  <StaticImage
                    className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                    src="../../assets/product-categories/plastic@2x.jpg"
                    width={768}
                    placeholder="blurred"
                    alt="Plastic very close"
                    objectFit="cover"
                    objectPosition="center"
                    backgroundColor="#4a91cb"
                    style={{ position: "absolute" }}
                  />
                )}
                {colorTheme === "Red" && (
                  <StaticImage
                    className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                    src="../../assets/product-categories/jam@2x.jpg"
                    width={768}
                    placeholder="blurred"
                    alt="Jam very close"
                    objectFit="cover"
                    objectPosition="center"
                    backgroundColor="#bf4944"
                    style={{ position: "absolute" }}
                  />
                )}
                {colorTheme === "Yellow" && (
                  <StaticImage
                    className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                    src="../../assets/product-categories/honey@2x.jpg"
                    width={768}
                    placeholder="blurred"
                    alt="Honey very close"
                    objectFit="cover"
                    objectPosition="center"
                    backgroundColor="#c48f46"
                    style={{ position: "absolute" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-px">
          <div className="px-6 py-8 -mx-6 sm:-mx-fluidMargin space-y-7 md:mx-0 md:py-16 sm:px-fluidMargin md:px-16 ring-1 ring-mineralGray bg-lightBlue">
            {formats?.length > 0 && (
              <>
                {formatsTitle && (
                  <h2 className="text-darkNavy h4">{formatsTitle}</h2>
                )}
                <ul className="grid grid-cols-4 gap-8 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
                  {formats?.map((format, i) => {
                    return (
                      <li key={`certification-${i}`}>
                        <Tooltip label={format.name} placement="top">
                          <div className="aspect-w-1 aspect-h-1">
                            <Asset
                              asset={format.image}
                              objectFit="contain"
                              objectPosition="center"
                              style={{ position: "absolute" }}
                            />
                          </div>
                        </Tooltip>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
          <div className="relative flex flex-col flex-1 -mx-6 sm:-mx-fluidMargin xl:-mr-26 md:ml-0">
            <div className="flex flex-col justify-between flex-1 -mr-px bg-white ring-1 ring-mineralGray">
              {videoText && (
                <div className="px-6 pt-8 pb-6 space-y-4 sm:px-fluidMargin md:pt-16 md:pl-16 md:pr-36 md:pb-14">
                  <RichText
                    content={videoText}
                    size="large"
                    options={{
                      renderNode: {
                        [BLOCKS.HEADING_2]: (node, children) => (
                          <h2 className="mt-8 mb-4 h3 text-darkNavy last:mb-0 first:mt-0">
                            {children}
                          </h2>
                        ),
                      },
                    }}
                  />
                </div>
              )}
              <div className="pr-px">
                <div className="aspect-w-2 aspect-h-1">
                  {videoPreview && (
                    <Asset
                      asset={videoPreview}
                      objectFit="cover"
                      objectPosition="top"
                      style={{ position: "absolute" }}
                    />
                  )}
                  {video && (
                    <LazyLoad
                      classNamePrefix="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                      once
                    >
                      <Asset
                        className="absolute top-0 bottom-0 left-0 right-0 object-cover object-top w-full h-full"
                        asset={video}
                      />
                    </LazyLoad>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex-1 block -mx-6 sm:-mx-fluidMargin md:-mx-26 lg:hidden ring-1 ring-mineralGray">
            <div className="relative h-full">
              {colorTheme === "Purple" && (
                <StaticImage
                  className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                  src="../../assets/product-categories/jelly.jpg"
                  width={768}
                  placeholder="blurred"
                  alt="Jelly very close"
                  objectFit="cover"
                  objectPosition="center"
                  backgroundColor="#8e426c"
                  style={{ position: "absolute" }}
                />
              )}
              {colorTheme === "Blue" && (
                <StaticImage
                  className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                  src="../../assets/product-categories/plastic.jpg"
                  width={768}
                  placeholder="blurred"
                  alt="Plastic very close"
                  objectFit="cover"
                  objectPosition="center"
                  backgroundColor="#4a91cb"
                  style={{ position: "absolute" }}
                />
              )}
              {colorTheme === "Red" && (
                <StaticImage
                  className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                  src="../../assets/product-categories/jam.jpg"
                  width={768}
                  placeholder="blurred"
                  alt="Jam very close"
                  objectFit="cover"
                  objectPosition="center"
                  backgroundColor="#bf4944"
                  style={{ position: "absolute" }}
                />
              )}
              {colorTheme === "Yellow" && (
                <StaticImage
                  className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                  src="../../assets/product-categories/honey@2x.jpg"
                  width={768}
                  placeholder="blurred"
                  alt="Honey very close"
                  objectFit="cover"
                  objectPosition="center"
                  backgroundColor="#c48f46"
                  style={{ position: "absolute" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
