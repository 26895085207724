import React from "react";

import { Decorator } from "components";

import Card from "./_Card";

export function LinkCards({ decorators, heading, linkCards }) {
  return (
    <section data-slice="LinkCards" className="md:pb-16 wrapper space-y-16">
      {heading && (
        <div className="lg:h-auto lg:col-span-2 relative h-40 col-span-2 row-span-1 row-start-1">
          <div className="md:w-1/2 w-full m-auto">
            <h2 className="h2 text-darkNavy text-center">{heading}</h2>
          </div>
          {decorators && decorators[0] && (
            <Decorator
              className="top-7 absolute left-0 transform -translate-x-1/2"
              asset={decorators && decorators[0].asset}
              size="small"
            />
          )}

          {decorators && decorators[1] && (
            <Decorator
              className="absolute bottom-0 right-0"
              asset={decorators && decorators[1].asset}
              size="small"
            />
          )}
        </div>
      )}
      <ul className="default-grid sm:-mx-fluidMargin md:mx-0 gap-y-0 -mx-6">
        {linkCards?.map((item, i) => {
          return <Card {...item} key={`linkCard--${i}`} />;
        })}
      </ul>
    </section>
  );
}
