import React from "react";

import ListItem from "./_ListItem";

export function ListGrid({ heading, items }) {
  if (items && items[0]?.title && items[0]?.content)
    return (
      <section
        data-slice="ListGrid"
        className="relative space-y-16 bg-white lg:space-y-32 wrapper py-14 lg:pt-30 lg:pb-36"
      >
        {heading && <h2 className="text-center h1 text-darkNavy">{heading}</h2>}
        <ul className="grid-cols-6 default-grid lg:grid-cols-12 gap-y-8 sm:gap-y-14">
          {items?.map((item, i) => (
            <ListItem {...item} key={`listItem--${i}`} />
          ))}
        </ul>
      </section>
    );
  else return null;
}
