import React, { useEffect, useRef, useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { useLocation } from "@reach/router";
import {
  AnimatePresence,
  createDomMotionComponent,
  motion,
} from "framer-motion";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import clsx from "clsx";

import { getAllButLastWord, getLastWord } from "utils";

import { HFSLogo } from "../HFSLogo";
import { Icon } from "../Icon";
import { Link } from "../Link";
import { SwitchRegion } from "../SwitchRegion/SwitchRegion";

// import Accordion from "./_Accordion"
import Item from "./_Item";

const MotionDialogOverlay = createDomMotionComponent(DialogOverlay);
const MotionDialogContent = createDomMotionComponent(DialogContent);

function PrimaryNav({ label, href }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Link
      href={href}
      className="text-darkNavy h3 focus:outline-none hover:text-darkNavy focus:text-violet hover:bg-transparent focus:bg-transparent p-0"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {getAllButLastWord(label.split(" | ")[0])}
      <span className="whitespace-nowrap inline-flex items-center flex-shrink-0">
        {getLastWord(label.split(" | ")[0])}
        <span
          className="flex items-center justify-center"
          style={{
            width: "0.375em",
            height: "0.375em",
            marginTop: "0.25em",
            marginLeft: "0.375em",
          }}
        >
          <Icon
            className={clsx(
              "text-violet absolute transform transition-transform ease-out duration-300",
              isHovering === true && "translate-x-1"
            )}
            symbol="arrow-right"
            size="0.5em"
          />
        </span>
      </span>
    </Link>
  );
}

function MobileMenu({ children, dropdowns, primaryLinks, secondaryLinks }) {
  const location = useLocation();
  const [showDialog, setShowDialog] = useState(false);
  const closeButtonRef = useRef();

  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  useEffect(() => {
    if (showDialog) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {React.cloneElement(children, { onClick: open })}
      <AnimatePresence>
        {showDialog && (
          <MotionDialogOverlay
            className="z-mobileMenu fixed top-0 bottom-0 left-0 flex w-full bg-white"
            isOpen={showDialog}
            initialFocusRef={closeButtonRef}
            onDismiss={close}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                type: "easeOut",
                duration: 0.25,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.1,
              },
            }}
            layout
          >
            <MotionDialogContent
              className="w-full p-0 m-0 bg-white outline-none"
              aria-label="Menu"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  type: "easeOut",
                  duration: 0.25,
                  delay: 0.2,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  type: "easeOut",
                  duration: 0.15,
                },
              }}
              style={{ maxWidth: "100vw" }}
              layout
            >
              <div className="wrapper relative">
                <div className="flex items-center justify-between h-32">
                  <GatsbyLink
                    to="/"
                    className="text-darkPurple focus:outline-none focus:text-violet"
                  >
                    <HFSLogo
                      titleId="hfs-logo_mobileMenu_01"
                      className="sm:ml-0 lg:-mt-6 md:-ml-6 xl:-ml-14 mt-2 text-current duration-150 ease-out transform"
                      height={80}
                      onClick={close}
                    />
                  </GatsbyLink>
                  <div className="-mt-10">
                    <button
                      ref={closeButtonRef}
                      className="w-11 h-11 focus:outline-none flex items-center justify-center"
                      onClick={close}
                    >
                      <Icon
                        className="text-darkPurple"
                        symbol="times"
                        size={32}
                      />
                      <span className="sr-only">Close</span>
                    </button>
                  </div>
                </div>
                <motion.nav
                  initial={{ y: 16, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "easeOut",
                      duration: 0.25,
                      delay: 0.3,
                    },
                  }}
                  exit={{
                    y: 16,
                    opacity: 0,
                    transition: {
                      type: "easeOut",
                      duration: 0.15,
                    },
                  }}
                >
                  <ul className="space-y-8">
                    {dropdowns?.map((item, i) => (
                      <li className="first:mt-6" key={`dropdownItems-${i}`}>
                        <Item {...item} />
                      </li>
                    ))}
                    {primaryLinks?.length > 0 &&
                      primaryLinks?.map(({ label, link }, i) => (
                        <li className="first:mt-6" key={`primaryItems-${i}`}>
                          <PrimaryNav label={label} href={link?.link} />
                        </li>
                      ))}
                  </ul>
                </motion.nav>
                {secondaryLinks?.length > 0 && (
                  <motion.nav
                    className="flex items-center pt-20 pb-6 space-x-4"
                    initial={{ y: 16, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: "easeOut",
                        duration: 0.25,
                        delay: 0.4,
                      },
                    }}
                    exit={{
                      y: 16,
                      opacity: 0,
                      transition: {
                        type: "easeOut",
                        duration: 0.15,
                      },
                    }}
                  >
                    {secondaryLinks?.map(({ label, link }, index) => (
                      <Link
                        className="nav-primary text-mineralGray text-link"
                        href={link?.link}
                        key={`secondaryLink-${index}`}
                      >
                        {label}
                      </Link>
                    ))}
                    <SwitchRegion />
                  </motion.nav>
                )}
              </div>
            </MotionDialogContent>
          </MotionDialogOverlay>
        )}
      </AnimatePresence>
    </>
  );
}

export default MobileMenu;
