module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hearthside Food Solutions Europe","short_name":"Hearthside Europe","description":"We work in partnerships with true collaboration. From concept, to production, and transport, we offer the behind-the-scenes support and expertise needed to make your brand shine.","lang":"en","display":"standalone","icon":"src/assets/icon.svg","start_url":"/","background_color":"#4D244D","theme_color":"#FFFFFF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"21f8ec47d5adf94615435543c845c6cb"},
    }]
