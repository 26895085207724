import React from "react";

import { Link } from "../Link";

function Meta({ copyright, secondaryLinks }) {
  return (
    <section className="bg-white py-14">
      <div className="wrapper default-grid">
        <div className="flex flex-col items-center justify-between space-y-4 col-span-full lg:col-span-10 lg:col-start-3 lg:flex-row lg:space-y-0">
          <p className="text-center lg:text-left small-copy">{copyright}</p>
          <ul className="flex space-x-4 lg:space-x-8 small-copy">
            {secondaryLinks.map(({ label, link }, index) => (
              <li key={`footerSecondaryLink-${index}`}>
                <Link
                  href={link?.link}
                  className="transition-colors duration-150 ease-out focus:text-violet"
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div style={{ paddingBottom: "env(safe-area-inset-bottom)" }} />
    </section>
  );
}

export default Meta;
