import React, { useState } from "react";
import clsx from "clsx";

import { Asset, RichText } from "components";

function TeamMember({ bio, headshot, name, position }) {
  const [showFullBio, setShowFullBio] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <li className="space-y-4 lg:col-span-6 col-span-full ">
      <div className="grid grid-cols-6 gap-x-8">
        <div className={"bg-mineralGray aspect-w-1 aspect-h-1 col-span-2"}>
          {headshot && (
            <Asset
              asset={headshot}
              objectFit="cover"
              objectPosition="center"
              style={{ position: "absolute" }}
            />
          )}
        </div>
        <div className="col-span-4">
          <div className="space-y-2">
            {name && <h3 className="text-violet h5">{name}</h3>}
            {position && (
              <p className="bold-subtitle text-mineralGray">{position}</p>
            )}
          </div>
          {bio && (
            <div className="space-y-2">
              <RichText
                className={!showFullBio && "line-clamp-4 lg:line-clamp-5"}
                content={bio}
                size="small"
              />
              <button
                className={clsx(
                  "text-left transition-opacity duration-150 ease-out text-violet body focus:outline-none focus:underline",
                  isHovering === true && "opacity-75"
                )}
                style={{
                  textDecorationThickness: 2,
                  textUnderlineOffset: 2,
                }}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                onClick={() => setShowFullBio(!showFullBio)}
              >
                {!showFullBio ? "More" : "Less"}
              </button>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

export default TeamMember;
