import React, { forwardRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
import clsx from "clsx";

const linkStyles = "focus:outline-none";

// A small helper utility to properly insert the correct variety of link (eg., anchor scrolling link, regular <a> link, or a gatsby link for internals links) depending on the CMS value
export const Link = forwardRef(
  ({ className, children, href, ...rest }, ref) => {
    if (href && typeof href === "string") {
      if (
        href.startsWith("//assets.ctfassets.net/") ||
        href.startsWith("http://") ||
        href.startsWith("https://") ||
        href.startsWith("mailto:") ||
        href.startsWith("tel:")
      ) {
        return (
          <a
            className={clsx(linkStyles, className)}
            ref={ref}
            target="_blank"
            rel="noopener noreferrer"
            href={href}
            {...rest}
          >
            {children}
          </a>
        );
      }
      if (href.startsWith("/")) {
        return (
          <GatsbyLink
            className={clsx(linkStyles, className)}
            ref={ref}
            to={href.endsWith("/") ? href : href + "/"}
            {...rest}
          >
            {children}
          </GatsbyLink>
        );
      }
      if (href.startsWith("#")) {
        return (
          <ScrollLink
            className={clsx(linkStyles, className)}
            ref={ref}
            spy={true}
            offset={-80}
            smooth={true}
            to={href.replace("#", "")}
            {...rest}
          >
            {children}
          </ScrollLink>
        );
      }
      // Handle the cases where it starts with none of the above and hide. This is beneficial to prevent broken links, but also to prevent script injection using an anchor using javascript:
      return null;
    } else {
      return null;
    }
  }
);
