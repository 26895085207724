import React from "react";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { Asset } from "../Asset";
import { Link } from "../Link";

const largeStyles = ({ ...options }) => {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (node?.data?.target) {
          return (
            <div className="my-8 last:mb-0 first:mt-0">
              <Asset asset={node.data.target} />
            </div>
          );
        } else {
          return null;
        }
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <h2 className="mt-8 mb-8 h1 text-darkPurple dark:text-thistle last:mb-0 first:mt-0">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="mt-8 mb-8 h2 text-darkNavy dark:text-thistle last:mb-0 first:mt-0">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="mt-8 mb-4 h3 text-darkNavy dark:text-thistle last:mb-0 first:mt-0">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h3 className="mt-8 mb-4 h4 text-darkNavy dark:text-thistle last:mb-0 first:mt-0">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h3 className="mt-8 mb-4 h5 text-darkNavy dark:text-thistle last:mb-0 first:mt-0">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h3 className="mt-8 mb-4 cap-subtitle last:mb-0 first:mt-0">
          {children}
        </h3>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="pl-6 my-12 space-y-4 list-disc last:mb-0 first:mt-0">
          {children}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="pl-6 my-12 space-y-4 list-decimal last:mb-0 first:mt-0">
          {children}
        </ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
        });
        return <li className="lead-copy">{UnTaggedChildren}</li>;
      },
      [BLOCKS.QUOTE]: (node, children) => {
        const UntaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.QUOTE]: (node, children) => children,
          },
        });
        return (
          <figure className="my-12 last:mb-0 first:mt-0 md:my-24">
            <blockquote className="flex h2 text-darkNavy quote">
              <div
                aria-hidden
                className="text-left select-none text-violet"
                style={{ width: "1em" }}
              >
                “
              </div>
              <div className="flex-1">
                {UntaggedChildren}
                <span aria-hidden className="text-left select-none text-violet">
                  ”
                </span>
              </div>
            </blockquote>
          </figure>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if ((children && children[0]) || children.length > 1) {
          return (
            <p className="my-4 lead-copy text-mineralGray dark:text-lightBlue last:mb-0 first:mt-0">
              {children}
            </p>
          );
        } else {
          return null;
        }
      },
      [INLINES.HYPERLINK]: (node, children, uri) => {
        return (
          <Link className="text-link" href={node.data.uri}>
            {children}
          </Link>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node, children, uri) => {
        return (
          <Link className="text-link" href={node?.data?.target?.file?.url}>
            {node?.content && node.content[0] && node.content[0].value}
          </Link>
        );
      },
      ...options.renderNode,
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <strong className="font-bold">{text}</strong>,
      [MARKS.ITALIC]: (text) => <em className="italic">{text}</em>,
      ...options.renderMark,
    },
  };
};

export default largeStyles;
