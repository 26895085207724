import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Icon({ symbol, size, style, ...rest }) {
  return (
    <FontAwesomeIcon
      data-component="Icon"
      icon={symbol}
      size="1x"
      style={{ fontSize: size || 24, ...style }}
      {...rest}
    />
  );
}
