import React, { useEffect, useRef, useState } from "react";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import clsx from "clsx";

import { useHeaderContext } from "../../contexts/header";
import { AnnouncementBar } from "../AnnouncementBar";
import { HFSLogo } from "../HFSLogo";
import { Icon } from "../Icon";
import { Link } from "../Link";
import { SwitchRegion } from "../SwitchRegion";

import MobileMenu from "./_MobileMenu";

let priorScroll = 0;

export function Header() {
  const data = useStaticQuery(HEADER_QUERY);
  const header = data?.contentfulHeader;

  const { headerPosition, headerState, setHeaderPosition, setHeaderState } =
    useHeaderContext();
  const headerRef = useRef();
  const announcementBarRef = useRef();
  const [announcementVisible, setAnnouncementVisible] = useState(
    header?.announcement && header?.announcementLink?.link?.link
  );
  const [announcementHeight, setAnnouncementHeight] = useState(0);

  // handle scrolling to handle the changes in header state
  const handleScroll = () => {
    const top = window.scrollY;
    if (top > priorScroll && headerState !== "hidden") {
      setHeaderState("hidden");
    }
    if (top > priorScroll) {
      priorScroll = top;
    }
    if (top + 4 <= priorScroll && headerState !== "visible") {
      setHeaderState("visible");
    }
    if (top + 4 <= priorScroll) {
      priorScroll = top;
    }
    // Handle positioning props
    if (top > announcementHeight + 144 && headerPosition !== "fixed") {
      setHeaderPosition("fixed");
      // mumbo jumbo to reset the transition duration on fixed to prevent flash of header as it swaps
      if (headerRef?.current) {
        headerRef.current.style.transitionDuration = "0ms";
        setTimeout(() => {
          headerRef.current.style.transitionDuration = "";
        }, 10);
      }
    }
    if (top <= announcementHeight && headerPosition !== "") {
      setHeaderPosition("");
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementHeight, headerPosition, headerRef, headerState]);

  // handle resizing events to measure the announcementBar, if relevant
  const handleResize = () => {
    if (announcementBarRef?.current) {
      setAnnouncementHeight(
        announcementBarRef.current.getBoundingClientRect().height
      );
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, [announcementBarRef, announcementVisible]);

  return (
    <header data-component="Header">
      <div data-component="Header.AnnouncementBar" ref={announcementBarRef}>
        {header?.announcement && header?.announcementLink && (
          <AnnouncementBar
            announcement={header?.announcement}
            link={header?.announcementLink?.link}
            label={header?.announcementLink?.label}
            visible={announcementVisible}
            setVisible={setAnnouncementVisible}
          />
        )}
      </div>
      <div
        data-component="Header.Navigation"
        ref={headerRef}
        id="header"
        className={clsx(
          "transition-transform duration-300 ease-out transform z-header bg-white border-b-2 border-transparent",
          headerPosition === "fixed" &&
            "fixed left-0 top-0 w-full border-b border-marble",
          headerPosition === "fixed" &&
            headerState !== "visible" &&
            "-translate-y-full",
          headerPosition !== "fixed" && "relative dark bg-darkPlum"
        )}
      >
        <div className="relative wrapper">
          <div
            className={clsx(
              "flex items-center justify-between",
              headerPosition === "fixed" ? "h-18 lg:h-24" : "h-32"
            )}
          >
            <div className="flex w-full">
              <div
                className={clsx(
                  headerPosition === "fixed" &&
                    "ml-11 lg:ml-0 flex justify-center flex-1 lg:flex-none items-center"
                )}
              >
                <GatsbyLink
                  to="/"
                  className="text-darkPurple dark:text-sauvignon focus:outline-none focus:text-violet"
                >
                  <HFSLogo
                    titleId="hfs-logo_header_01"
                    className={clsx(
                      "sm:ml-0 md:-ml-6 xl:-ml-14 transform duration-150 ease-out",
                      headerPosition === "fixed"
                        ? "scale-60 lg:scale-75"
                        : "mt-2 lg:-mt-6 scale-100"
                    )}
                    height={80}
                  />
                </GatsbyLink>
              </div>
              <nav className="items-center hidden pl-20 space-x-8 lg:flex">
                {header?.primaryLinks?.length > 0 &&
                  header?.primaryLinks?.map(({ label, link }, i) => (
                    <Link
                      className="transition-opacity duration-150 ease-out nav-primary text-mineralGray dark:text-lightBlue focus:text-violet focus:outline-none hover:opacity-75"
                      href={link?.link}
                      key={`primaryLink--${i}`}
                    >
                      {label}
                    </Link>
                  ))}
              </nav>
              {header?.secondaryLinks?.length > 0 && (
                <nav className="items-center hidden ml-auto space-x-6 lg:flex">
                  {header?.secondaryLinks?.map(({ label, link }, index) => (
                    <Link
                      className="transition-all duration-150 ease-out outline-none nav-secondary text-mineralGray dark:text-lightBlue focus:text-violet hover:opacity-75 focus:outline-none"
                      href={link?.link}
                      key={`secondaryLink-${index}`}
                    >
                      {label}
                    </Link>
                  ))}
                  <SwitchRegion />
                </nav>
              )}
            </div>
            <div
              className={clsx(
                "block lg:hidden",
                headerPosition === "fixed" ? "" : "-mt-10"
              )}
            >
              <MobileMenu {...header}>
                <button className="flex items-center justify-center w-11 h-11">
                  <Icon
                    className="text-darkPurple dark:text-lightBlue"
                    symbol="bars"
                    size={32}
                  />
                  <span className="sr-only">Menu</span>
                </button>
              </MobileMenu>
            </div>
          </div>
        </div>
      </div>
      {headerPosition === "fixed" && (
        <div data-component="Header.Spacer" className="pb-32 md:pb-36" />
      )}
      <div className="ie-banner">
        <div className="wrapper">
          <h1 className="h2">
            This website is not optimized for Internet Explorer.
          </h1>
          <p className="mt-4 lead-copy">
            For the best experience, please use a newer browser such as Edge,
            Chrome, Safari, or Firefox.
          </p>
        </div>
      </div>
    </header>
  );
}

const HEADER_QUERY = graphql`
  query Header {
    contentfulHeader {
      announcement
      announcementLink {
        ...LinkFragment
      }
      primaryLinks {
        ...LinkFragment
      }
      secondaryLinks {
        ...LinkFragment
      }
    }
  }
`;
