import React from "react";

import { Icon } from "../Icon";
import { TextLink } from "../TextLink";

export function AnnouncementBar({
  announcement,
  label = "",
  link = "",
  visible,
  setVisible,
}) {
  return (
    <>
      {visible && (
        <div
          data-component="AnnouncementBar"
          className="relative text-white bg-violet z-header"
        >
          <div className="wrapper">
            <div className="relative pb-6 pt-9 md:py-8">
              <div className="relative flex flex-col flex-wrap items-center justify-center space-y-2 text-center md:px-11 md:-my-1 md:flex-row md:space-x-4 md:space-y-0">
                <p className="bold-subtitle md:py-1">{announcement}</p>
                {link?.link && label && (
                  <p className="body md:py-1">
                    <TextLink
                      label={label}
                      href={link?.link}
                      className="text-white"
                    />
                  </p>
                )}
              </div>
              <button
                className="flex items-center justify-center rounded-full w-11 h-11 focus:outline-none focus:ring-1 ring-white absolute md:transform md:-translate-y-1/2 -right-3.5 top-0 md:top-1/2"
                onClick={() => setVisible(false)}
              >
                <Icon aria-hidden symbol="times" size={18} />
                <span className="sr-only">Close</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
