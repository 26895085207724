import React from "react"

function KeyLines() {
  return (
    <div className="wrapper">
      <div className="relative w-full h-0">
        <div className="absolute top-0 left-0 w-40 h-px origin-left transform -translate-x-16 bg-mineralGray" />
        <div className="absolute top-0 left-0 h-px origin-left transform -rotate-90 -translate-x-8 translate-y-24 w-80 bg-mineralGray" />
      </div>
    </div>
  )
}

export default KeyLines
