import React from "react";

import { Button, Decorator } from "components";

export function Callout({ button, decorators, heading }) {
  return (
    <section
      data-component="Callout"
      id="callout"
      className="wrapper md:py-30 default-grid relative py-32"
    >
      {decorators && decorators[0] && (
        <Decorator
          className="-top-4 -translate-x-1/3 md:-translate-x-1/2 md:-translate-y-1/2 lg:translate-x-0 md:left-26 md:top-1/2 absolute left-0"
          asset={decorators && decorators[0].asset}
          size="small"
        />
      )}
      {decorators && decorators[1] && (
        <Decorator
          className="translate-x-1/3 md:translate-x-1/2 md:-translate-y-1/2 lg:translate-x-0 md:right-26 md:top-1/2 absolute bottom-0 right-0"
          asset={decorators && decorators[1].asset}
          size="small"
        />
      )}
      <div className="xl:col-span-6 col-span-full lg:col-span-8 lg:col-start-3 xl:col-start-4 space-y-8 text-center">
        {heading && (
          <h2 className="h3 text-darkNavy mx-auto" style={{ maxWidth: "16ch" }}>
            {heading}
          </h2>
        )}
        {button.label && button.link.link && (
          <Button
            label={button.label}
            href={button.link.link}
            appearance="primary"
            size="large"
          />
        )}
      </div>
    </section>
  );
}
