import React from "react"
import { createDomMotionComponent } from "framer-motion"
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext,
} from "@reach/accordion"
import clsx from "clsx"

import { Icon, RichText } from "components"

const MotionAccordionPanel = createDomMotionComponent(AccordionPanel)

const motion = {
  div: createDomMotionComponent("div"),
}

const panelVariants = {
  open: {
    height: "auto",
    transition: {
      type: "easeOut",
      duration: 0.4,
      delayChildren: 0.2,
    },
  },
  closed: {
    height: 0,
    transition: {
      when: "afterChildren",
    },
  },
}

const contentVariants = {
  open: {
    opacity: 1,
    pointerEvents: "auto",
    y: 0,
    transition: {
      type: "easeOut",
      duration: 0.3,
    },
  },
  closed: {
    opacity: 0,
    pointerEvents: "none",
    y: 16,
    transition: {
      type: "easeOut",
      duration: 0.2,
    },
  },
}

function Inner({ content, title }) {
  const { isExpanded } = useAccordionItemContext()

  if (title && content)
    return (
      <>
        <h3>
          <AccordionButton
            className={clsx(
              "flex w-full text-left lead-bold text-darkNavy focus:outline-none focus:text-violet",
              isExpanded ? "py-4 md:pt-8 md:pb-4" : "py-4 md:py-8"
            )}
          >
            <span
              className="md:justify-end md:w-18 flex w-auto pr-4"
              style={{ marginTop: "0.875em", fontSize: "0.5em" }}
            >
              <Icon
                className="text-violet"
                symbol={isExpanded ? "minus" : "plus"}
                size="1em"
              />
            </span>
            <span>{title}</span>
          </AccordionButton>
        </h3>
        <MotionAccordionPanel
          className="focus:outline-none"
          hidden={false}
          aria-hidden={!isExpanded}
          animate={isExpanded ? "open" : "closed"}
          initial="closed"
          variants={panelVariants}
          layout
        >
          <motion.div
            className="md:px-18 md:pb-8 pb-6"
            variants={contentVariants}
          >
            <RichText content={content} size="small" />
          </motion.div>
        </MotionAccordionPanel>
      </>
    )
  else return null
}

function Item({ title, content }) {
  return (
    <AccordionItem as="li">
      <Inner title={title} content={content} />
    </AccordionItem>
  )
}

export default Item
