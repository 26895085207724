import React from "react";
import Helmet from "react-helmet";
import slugify from "slugify";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import * as Reach from "@reach/accordion";
import "@reach/accordion/styles.css";

import Item from "./_Item";

import { Decorator, RichText } from "components";

export function Accordion({ accordionItems, content, decorators }) {
  // extract ID from content, if relevant
  let id = "";
  if (content?.raw) {
    let contentParsed = JSON.parse(content.raw);
    if (
      contentParsed?.content &&
      contentParsed?.content[0]?.nodeType?.startsWith("heading")
    ) {
      id = contentParsed?.content[0]?.content[0]?.value;
    }
  }

  if (accordionItems && accordionItems[0])
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": ${JSON.stringify(
                  accordionItems?.map((accordion) => {
                    return {
                      "@type": "Question",
                      name: accordion?.title,
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: accordion?.content?.raw
                          ? documentToHtmlString(
                              JSON.parse(accordion.content.raw)
                            )
                          : "",
                      },
                    };
                  })
                )}
              }`}
          </script>
        </Helmet>

        <section
          data-slice="Accordion"
          id={id && slugify(id)}
          className="relative pt-0 pb-16 md:pt-12 wrapper default-grid md:pb-24 gap-y-0"
        >
          {((decorators && decorators[0]) || (decorators && decorators[1])) && (
            <div className="relative h-40 col-span-2 row-span-1 row-start-1 lg:h-auto lg:col-span-2">
              {decorators && decorators[0] && (
                <Decorator
                  className="absolute top-0 hidden -translate-x-full -translate-y-1/2 md:block left-full"
                  asset={decorators[0].asset}
                  size="large"
                />
              )}
              {decorators && decorators[1] && (
                <Decorator
                  className="absolute top-0 -translate-x-full md:hidden left-full"
                  asset={decorators[1].asset}
                  size="small"
                />
              )}
            </div>
          )}
          <div className="space-y-8 md:col-start-3 md:col-span-8 col-span-full md:space-y-10">
            {content && <RichText content={content} size="large" />}
            <Reach.Accordion
              as="ul"
              className="border-t border-opacity-50 divide-y border-mineralGray divide-mineralGray divide-opacity-50"
              collapsible={true}
              multiple={true}
            >
              {accordionItems?.map((item, i) => (
                <Item {...item} key={`item--${i}`} />
              ))}
            </Reach.Accordion>
          </div>
        </section>
      </>
    );
  else return null;
}
