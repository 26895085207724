// Uses the Contentful rich text renderer to convert rich text to proper HTML nodes. Allows for default behaviour but can supersede.
// Documentation can be found here: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer

import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import largeStyles from "./_largeStyles";
import smallStyles from "./_smallStyles";

export const RichText = ({
  content,
  size = "large",
  options = {},
  ...rest
}) => {
  if (content) {
    return (
      <div data-component="RichText" {...rest}>
        {renderRichText(
          content,
          size === "large"
            ? largeStyles(options)
            : size === "small"
            ? smallStyles(options)
            : largeStyles(options)
        )}
      </div>
    );
  } else {
    return null;
  }
};
