import React from "react"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"

function Tooltip({
  label,
  description,
  children,
  placement = "top",
  maxWidth,
  ...rest
}) {
  return (
    <Tippy
      className="tooltip"
      placement={placement}
      maxWidth={maxWidth}
      content={
        <div className="space-y-2 text-center" style={{ minWidth: 80 }}>
          {label && <p className="font-bold body">{label}</p>}
          {description && <p className="small-copy">{description}</p>}
        </div>
      }
      {...rest}
    >
      {children}
    </Tippy>
  )
}

export default Tooltip
