import React from "react";
import slugify from "slugify";
import clsx from "clsx";
import { BLOCKS } from "@contentful/rich-text-types";

import { Button, RichText } from "components";

export function TextBlock({ button, decorators, content }) {
  // extract ID from content, if relevant
  let id = "";
  if (content?.raw) {
    let contentParsed = JSON.parse(content.raw);
    if (
      contentParsed?.content &&
      contentParsed?.content[0]?.nodeType?.startsWith("heading")
    ) {
      id = contentParsed?.content[0]?.content[0]?.value;
    }
  }

  function insetContent(content) {
    if (content?.raw) {
      let contentParsed = JSON.parse(content.raw);
      if (
        contentParsed?.content &&
        contentParsed?.content[0]?.nodeType?.startsWith("heading-1")
      ) {
        return "col-span-full";
      } else return "col-span-full md:col-span-10 md:col-start-2";
    }
  }

  return (
    <section
      data-slice="TextBlock"
      id={id && slugify(id)}
      className="relative py-16 wrapper md:py-24 default-grid"
    >
      {decorators?.map((item, index) => (
        <div key={"careerCardsDecorator" + index}>{item.asset.title}</div>
      ))}
      <div className={clsx("text-left", insetContent(content))}>
        {/* Left Decorator */}
        <div className="absolute top-0 left-0"></div>
        {/* Content */}
        <RichText
          content={content}
          size="large"
          options={{
            renderNode: {
              [BLOCKS.HEADING_1]: (node, children) => (
                <h2 className="mt-8 mb-8 h1 last:mb-0 first:mt-0 text-violet">
                  {children}
                </h2>
              ),
            },
          }}
        />
        {/* Button */}
        {button && button.label && (
          <Button
            label={button.label}
            to={button.link?.link}
            appearance="primary"
            size="large"
          />
        )}
      </div>
    </section>
  );
}
