import React, { useState } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { AnimatePresence, createDomMotionComponent } from "framer-motion";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

import { Asset, Button, Icon } from "components";

const MotionDialogOverlay = createDomMotionComponent(DialogOverlay);
const MotionDialogContent = createDomMotionComponent(DialogContent);
const MotionButton = createDomMotionComponent("button");

const OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="body text-mineralGray">{children}</p>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  },
};

const cardVariants = {
  initial: {
    scale: 1,
    transition: { type: "spring", bounce: 0.25, duration: 1 },
  },
  hover: {
    scale: 1.025,
    transition: { type: "spring", bounce: 0.25, duration: 0.5 },
  },
};

function VarietyCard({ name, description, image, index }) {
  const labelId = `label--${name}--${index}`;

  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <li className="flex">
      <article
        className="focus:outline-none focus:text-violet text-darkNavy flex flex-col w-full text-left bg-white"
        // onClick={open}
        // initial="initial"
        // whileHover="hover"
        // variants={cardVariants}
      >
        <div className="aspect-w-1 aspect-h-1 w-full select-none">
          {image && (
            <Asset
              asset={{ ...image, gatsbyImageData: image.small }}
              objectFit="cover"
              objectPosition="center"
              style={{ position: "absolute" }}
            />
          )}
        </div>
        <div className="md:pb-5 md:space-y-6 flex flex-col justify-between flex-1 w-full px-4 pt-4 pb-4 space-y-4">
          <p
            className="lead-bold text-current"
            style={{ minHeight: 1.3745 * 2 + "em" }}
          >
            {name}
          </p>
          {/* <div>
            <Icon className="text-violet" symbol="search-plus" size={16} />
            <span className="sr-only">Magnify glass</span>
          </div> */}
        </div>
      </article>

      {/* <AnimatePresence>
        {showDialog && (
          <MotionDialogOverlay
            className="z-dialog md:px-26 md:py-14 flex"
            isOpen={showDialog}
            onDismiss={close}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <MotionDialogContent
              aria-labelledby={labelId}
              className="bg-white"
              style={{
                width: "100%",
                maxWidth: 810,
                margin: "auto",
                padding: 0,
                boxShadow: "0px 30px 40px rgba(0, 0, 0, 0.25)",
              }}
              initial={{ y: 32 }}
              animate={{ y: 0 }}
              exit={{ y: -32 }}
              transition={{ type: "spring", bounce: 0.25, duration: 0.25 }}
            >
              <div className="md:px-14 py-14 relative px-12">
                <div className="top-4 right-4 md:top-10 md:right-10 absolute">
                  <button
                    className="w-11 h-11 focus:outline-none flex items-center justify-center"
                    onClick={close}
                  >
                    <Icon className="text-violet" symbol="times" size={18} />
                    <span className="sr-only">Close</span>
                  </button>
                </div>

                <div className="lg:space-y-14 space-y-8">
                  <div className="lg:space-y-6 lg:pr-26 md:pb-0 pb-6 space-y-4">
                    <h1 id={labelId} className="h2 text-darkNavy">
                      {name}
                    </h1>
                    <div className="space-y-4">
                      {renderRichText(description, OPTIONS)}
                    </div>
                  </div>
                  {image && (
                    <div className="md:mx-0 -mx-12">
                      <div className="aspect-w-1 md:aspect-w-2 aspect-h-1">
                        <Asset
                          asset={{ ...image, gatsbyImageData: image.large }}
                          objectFit="cover"
                          objectPosition="center"
                          style={{ position: "absolute" }}
                        />
                      </div>
                    </div>
                  )}
                  <hr className="border-mineralGray md:mx-0 -mx-6 border-opacity-50" />
                  <aside
                    className="md:mx-0 -mx-6 space-y-6 text-center"
                    style={{ paddingBottom: "env(safe-area-inset-bottom)" }}
                  >
                    <h2 className="lead-bold text-darkNavy">
                      Infinite formulations and formats
                    </h2>
                    <Button
                      label="Become a customer"
                      href="/contact/"
                      appearance="primary"
                      size="large"
                    />
                  </aside>
                </div>
              </div>
            </MotionDialogContent>
          </MotionDialogOverlay>
        )}
      </AnimatePresence> */}
    </li>
  );
}

export default VarietyCard;
