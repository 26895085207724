import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { Menu, MenuButton, MenuItems, MenuPopover } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import Sticky from "react-stickynode";
import clsx from "clsx";
import Portal from "@reach/portal";

import { useHeaderContext } from "../../contexts/header";

import { Icon } from "../Icon";
import { Link } from "../Link";

import NavItem from "./_NavItem";

export function Subheader({ links, title }) {
  const buttonRef = useRef();
  const location = useLocation();
  const [stickyState, setStickyState] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const { headerPosition, headerState } = useHeaderContext();

  const centered = (triggerRect, tooltipRect) => {
    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width;
    return {
      left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
      top: triggerRect.bottom + 8 + window.scrollY,
    };
  };

  const handleScroll = () => {
    if (buttonRef?.current?.getAttribute("aria-expanded") === "true") {
      buttonRef?.current?.dispatchEvent(
        new MouseEvent("mousedown", {
          bubbles: true,
          cancelable: true,
          screenX: 0,
          screenY: 0,
        })
      );
    }
  };

  // handle resizing events to adjust the sticky position
  const handleResize = () => {
    if (window?.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [buttonRef]);

  return (
    <Menu>
      {({ isExpanded }) => {
        return (
          <Sticky
            data-component="Subheader"
            innerClass={
              stickyState === 2 &&
              "transition-all duration-300 ease-out z-subheader"
            }
            top={
              headerPosition === "fixed" &&
              headerState === "visible" &&
              stickyState === 2
                ? isMobile
                  ? 74
                  : 96
                : 0
            }
            onStateChange={(state) => {
              setStickyState(state.status);
            }}
            innerZ={3}
          >
            <div
              className={clsx(
                "relative z-subheader border-b border-marble",
                stickyState === 2
                  ? "md:border-b bg-white"
                  : "dark bg-darkPlum md:border-b-0 md:border-t",
                isExpanded && (stickyState === 2 ? "bg-white" : "bg-darkPlum")
              )}
            >
              <div className="flex items-center justify-between h-16 md:pt-0 md:h-20 wrapper">
                {/* Desktop - Show title */}
                {title && (
                  <p className="hidden text-darkNavy dark:text-lightBlue lead-bold lg:block">
                    {title}
                  </p>
                )}
                {/* Mobile - Show current page name*/}
                <p
                  className={clsx(
                    "block lead-bold lg:hidden",
                    isExpanded
                      ? "text-violet dark:text-gold"
                      : "text-darkNavy dark:text-lightBlue"
                  )}
                >
                  {!isExpanded &&
                    links.map(({ label, link }, index) =>
                      location?.pathname ===
                      (link?.link?.endsWith("/")
                        ? link?.link
                        : link?.link + "/")
                        ? label
                        : null
                    )}
                  {isExpanded && title}
                </p>
                <nav className="hidden lg:block">
                  <ul className="flex space-x-10">
                    {links.map(({ label, link }, i) => (
                      <li key={`subnavlink--${i}`}>
                        <Link
                          className={clsx(
                            "font-bold body transition-colors duration-150 ease-out hover:text-violet dark:focus:text-gold dark:hover:text-gold focus:text-violet focus:outline-none outline-none",
                            location?.pathname ===
                              (link?.link?.endsWith("/")
                                ? link?.link
                                : link?.link + "/")
                              ? "text-violet dark:text-gold"
                              : "text-mineralGray dark:text-sauvignon"
                          )}
                          href={link?.link}
                        >
                          {label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="block lg:hidden">
                  <MenuButton
                    ref={buttonRef}
                    className="px-4 transition-shadow duration-150 ease-out rounded-full bg-violet lead-bold focus:outline-none focus:ring-3 ring-violet ring-opacity-50"
                  >
                    <Icon
                      className={clsx(
                        "text-white transform transition-transform ease-out duration-300 -translate-y-0.5",
                        isExpanded && "rotate-180"
                      )}
                      symbol="chevron-down"
                      size="0.75em"
                    />
                    <span className="sr-only">Menu</span>
                  </MenuButton>
                  <MenuPopover
                    style={{ width: "100vw" }}
                    position={centered}
                    className="z-subheader"
                  >
                    <div
                      className={clsx(
                        "lg:mt-8 border-marble mt-2 border-b",
                        stickyState === 2 ? "bg-white" : "bg-darkPlum dark"
                      )}
                    >
                      <div className="wrapper">
                        <MenuItems className="flex flex-col items-start space-y-10 bg-transparent border-0">
                          {links.map(({ label, link }, i) => (
                            <NavItem
                              href={link?.link}
                              label={label}
                              key={`dropdownLink-${i}`}
                            />
                          ))}
                        </MenuItems>
                      </div>
                    </div>
                  </MenuPopover>
                </div>
              </div>
            </div>
            {isExpanded && (
              <Portal>
                <div
                  className="fixed top-0 left-0 w-full h-full z-flag"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                />
              </Portal>
            )}
          </Sticky>
        );
      }}
    </Menu>
  );
}
