import React from "react";

import {
  Accordion,
  Callout,
  CertificationList,
  ColumnGrid,
  DetailGrid,
  Form,
  LinkCards,
  ListGrid,
  ProductGrid,
  ProductShowcase,
  SectionHeader,
  SideBySide,
  TeamGrid,
  Testimonial,
  TextBlock,
  Timeline,
} from "slices";

export function SliceZone({ content }) {
  if (content)
    return (
      <div>
        {/* Slice Zone */}
        {content.map(({ __typename, ...rest }, i) => {
          switch (__typename) {
            case "ContentfulAccordion": {
              return <Accordion {...rest} key={`content-accordion-${i}`} />;
            }
            case "ContentfulCallout": {
              return <Callout {...rest} key={`content-callout-${i}`} />;
            }
            case "ContentfulCertificationList": {
              return (
                <CertificationList
                  {...rest}
                  key={`content-certificationList--${i}`}
                />
              );
            }
            case "ContentfulColumnGrid": {
              return <ColumnGrid {...rest} key={`content-columnGrid-${i}`} />;
            }
            case "ContentfulDetailGrid": {
              return <DetailGrid {...rest} key={`content-detailGrid-${i}`} />;
            }
            case "ContentfulForm": {
              return <Form {...rest} key={`content-form-${i}`} />;
            }
            case "ContentfulLinkCards": {
              return <LinkCards {...rest} key={`content-linkCards--${i}`} />;
            }
            case "ContentfulListGrid": {
              return <ListGrid {...rest} key={`content-listGrid--${i}`} />;
            }
            case "ContentfulProductGrid": {
              return <ProductGrid {...rest} key={`productGrid-${i}`} />;
            }
            case "ContentfulProductShowcase": {
              return <ProductShowcase {...rest} key={`productShowcase-${i}`} />;
            }
            case "ContentfulSectionHeader": {
              return (
                <SectionHeader {...rest} key={`content-sectionHeader--${i}`} />
              );
            }
            case "ContentfulSideBySide": {
              return <SideBySide {...rest} key={`content-sideBySide--${i}`} />;
            }
            case "ContentfulTeamGrid": {
              return <TeamGrid {...rest} key={`content-teamGrid--${i}`} />;
            }
            case "ContentfulTestimonial": {
              return (
                <Testimonial {...rest} key={`content-testimonial--${i}`} />
              );
            }
            case "ContentfulTextBlock": {
              return <TextBlock {...rest} key={`content-textBlock--${i}`} />;
            }
            case "ContentfulTimeline": {
              return <Timeline {...rest} key={`content-timeline--${i}`} />;
            }
            default:
              return null;
          }
        })}
      </div>
    );
  else return null;
}
