import React from "react";
import LazyLoad from "react-lazyload";

import { Asset, TextLink } from "components";

export function Testimonial({ image, link, quote, video }) {
  return (
    <section data-slice="Testimonial" className="wrapper">
      <div className="pt-24 default-grid gap-y-0">
        <div className="space-y-6 col-span-full md:col-span-10 md:col-start-2">
          {quote && (
            <figure className="space-y-4 lg:space-y-6">
              <blockquote className="text-center h2 text-violet">
                {quote?.quote?.internal?.content}
              </blockquote>
              <figcaption className="text-center lead-bold lg:text-left text-gray">
                {quote?.attribution}
              </figcaption>
            </figure>
          )}
          {link && (
            <p className="text-center body lg:text-left">
              <TextLink
                label={link?.label}
                href={link?.link?.internal?.content}
              />
            </p>
          )}
        </div>
        {(image || video) && (
          <div className="relative col-span-full">
            <div className="aspect-w-16 aspect-h-9">
              {image && (
                <Asset
                  asset={image}
                  objectFit="contain"
                  objectPosition="center"
                  style={{ position: "absolute" }}
                />
              )}
            </div>
            {video && (
              <LazyLoad
                classNamePrefix="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                once
              >
                <Asset
                  className="absolute top-0 bottom-0 left-0 right-0 object-contain object-center w-full h-full"
                  asset={video}
                />
              </LazyLoad>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
