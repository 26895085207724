import React from "react";
import slugify from "slugify";

import { Decorator } from "components";

export function SectionHeader({ header, decorators }) {
  return (
    <section
      data-slice="SectionHeader"
      id={header?.heading && slugify(header?.heading)}
      className="relative py-40 wrapper default-grid md:py-30"
    >
      {decorators && decorators[0] && (
        <Decorator
          className="absolute bottom-0 left-0 -translate-x-1/2 md:translate-y-1/3 md:-translate-x-1/3"
          asset={decorators && decorators[0].asset}
          size="large"
        />
      )}
      {decorators && decorators[1] && (
        <Decorator
          className="absolute top-0 right-0"
          asset={decorators && decorators[1].asset}
          size="small"
        />
      )}
      <div className="md:col-span-8 md:col-start-3 col-span-full py-14 md:py-0">
        {header?.heading && (
          <h2 className="text-center whitespace-pre-line h3 text-darkNavy">
            {header.heading}
          </h2>
        )}
      </div>
    </section>
  );
}
