import React from "react";
import slugify from "slugify";

import { Asset, Button, Decorator } from "components";

import Item from "./_Item";

export function Timeline({ button, decorator, gallery, timeline, title }) {
  return (
    <section
      data-slice="Timeline"
      id={title && slugify(title)}
      className="relative grid-cols-6 pb-10 lg:pb-16 lg:pt-40 pt-72 wrapper default-grid gap-y-8 lg:gap-y-14 lg:grid-cols-12"
    >
      {decorator && (
        <div className="absolute top-0 left-0 lg:row-start-1 lg:relative lg:col-span-2">
          <Decorator
            className="absolute top-0 left-0 lg:-translate-y-6 lg:-top-40 lg:left-full lg:-translate-x-full"
            asset={decorator.asset}
            size="large"
          />
        </div>
      )}
      <div className="col-span-full sm:col-span-4 lg:col-span-6 space-y-14 lg:col-start-3 sm:row-start-1">
        {title && <h2 className="h5 text-darkNavy">{title}</h2>}
        <ol className="pr-10 space-y-8 lg:space-y-14 lg:pr-0">
          {timeline?.map((item, i) => (
            <Item {...item} key={`timelineItem--${i}`} />
          ))}
        </ol>
        {button?.link?.link && button?.label && (
          <Button label={button.label} href={button.link.link} />
        )}
      </div>
      <div className="hidden col-span-3 space-y-10 lg:col-span-4 lg:col-start-9 sm:block sm:row-start-1">
        {gallery?.map((image, i) => (
          <div
            className="aspect-w-4 aspect-h-3"
            key={`timelineGalleryItem--${i}`}
          >
            <Asset asset={image} style={{ position: "absolute" }} />
          </div>
        ))}
      </div>
    </section>
  );
}
