import React from "react"
import Portal from "@reach/portal"
import clsx from "clsx"

function Column({ onlyLarge }) {
  return (
    <div
      className={clsx(
        "bg-red border-l border-r border-red bg-opacity-10 border-opacity-50 border-dashed",
        onlyLarge && "md:block hidden"
      )}
    />
  )
}

function Margin({ right, left }) {
  return (
    <div
      className={clsx(
        "absolute top-0 h-full w-6 xl:w-26 sm:w-fluidMargin",
        right && "right-0",
        left && "left-0"
      )}
    >
      <div
        className={clsx(
          "h-full bg-blue border-blue bg-opacity-20 border-dashed border-opacity-50",
          right && "border-r",
          left && "border-l -ml-px"
        )}
        style={{ width: "calc(100% + 1px)" }}
      />
    </div>
  )
}

function DebugGrid() {
  return (
    <Portal>
      <div
        data-component="debugGrid"
        className="fixed top-0 left-0 w-full h-full pointer-events-none select-none z-debugGrid"
      >
        <div className="relative h-full wrapper">
          <div className="h-full default-grid">
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column onlyLarge />
            <Column onlyLarge />
            <Column onlyLarge />
            <Column onlyLarge />
            <Column onlyLarge />
            <Column onlyLarge />
          </div>
          <Margin right />
          <Margin left />
        </div>
      </div>
    </Portal>
  )
}

export default DebugGrid
