import React from "react";
import clsx from "clsx";

import { Asset } from "../Asset";

export const Decorator = ({ asset, size = "small", className, ...rest }) => {
  if (asset) {
    if (size === "small") {
      return (
        <div
          data-component="Decorator"
          aria-hidden
          className={clsx(
            "w-40 h-40 lg:w-44 lg:h-44 transform pointer-events-none select-none",
            className
          )}
        >
          <Asset asset={{ ...asset, gatsbyImageData: asset.small }} {...rest} />
        </div>
      );
    } else {
      return (
        <div
          data-component="Decorator"
          aria-hidden
          className={clsx(
            "w-64 h-64 lg:w-96 lg:h-96 transform pointer-events-none select-none",
            className
          )}
        >
          <Asset asset={{ ...asset, gatsbyImageData: asset.large }} {...rest} />
        </div>
      );
    }
  } else return null;
};
