import React from "react";

function Item({ description, title, value }) {
  if (title && description && value)
    return (
      <li className="grid grid-cols-6 gap-x-8 gap-y-2">
        <div
          className="col-span-full lg:col-span-1 cap-subtitle text-darkNavy"
          style={{ paddingTop: "0.5em" }}
        >
          {value}
        </div>
        <div className="space-y-2 col-span-full lg:col-span-5">
          <h3 className="lead-bold text-darkNavy">{title}</h3>
          <p className="max-w-sm body text-darkNavy">{description}</p>
        </div>
      </li>
    );
  else return null;
}

export default Item;
