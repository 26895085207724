function ringColor(appearance) {
  switch (appearance) {
    case "primary":
    case "secondary":
      return "rgba(104, 91, 181, .5)"
    case "tertiary":
      return "rgba(255, 255, 255, .25)"
    case "accepted":
      return "rgba(85, 176, 123, .5)"
    default:
      break
  }
}

export const buttonVariants = appearance => ({
  initial: {},
  hover: {},
  focus: {
    boxShadow: `0 0 0 3px ${ringColor(appearance)}`,
    transition: { type: "spring", bounce: 0.5, duration: 0.5 },
  },
  tap: {},
})

function bgColor(appearance) {
  switch (appearance) {
    case "secondary":
      return "#685BB5"
    case "tertiary":
      return "#FFFFFF"
    default:
      break
  }
}

export const backgroundVariants = appearance => ({
  initial: {
    transition: { type: "spring", bounce: 0.25, duration: 0.5 },
  },
  hover: {
    backgroundColor: bgColor(appearance),
    boxShadow: "0 5px 10px rgba(56, 56, 56, 0.1)",
    transition: { type: "spring", bounce: 0.25, duration: 0.5 },
  },
  tap: {
    backgroundColor: bgColor(appearance),
    boxShadow: "0 5px 10px rgba(56, 56, 56, 0.2)",
    scale: 1.02,
    transition: { type: "spring", bounce: 0.25, duration: 0.125 },
  },
})

export const scrimVariants = {
  initial: {
    opacity: 0,
    backgroundColor: "#FFFFFF",
    transition: { type: "spring", bounce: 0.25, duration: 0.5 },
  },
  hover: {
    opacity: 0.15,
    backgroundColor: "#FFFFFF",
    transition: { type: "spring", bounce: 0.25, duration: 0.25 },
  },
  tap: {
    opacity: 0.15,
    backgroundColor: "#383838",
    scale: 1.02,
    transition: { type: "spring", bounce: 0.25, duration: 0.125 },
  },
}

function labelColor(appearance) {
  switch (appearance) {
    case "secondary":
      return "#FFFFFF"
    case "tertiary":
      return "#685BB5"
    default:
      break
  }
}

export const labelVariants = appearance => ({
  initial: {
    transition: { type: "spring", bounce: 0.25, duration: 0.5 },
  },
  hover: {
    color: labelColor(appearance),
    transition: { type: "spring", bounce: 0.25, duration: 0.125 },
  },
  tap: {
    color: labelColor(appearance),
    transition: { type: "spring", bounce: 0.25, duration: 0.125 },
  },
})
