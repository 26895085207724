import React from "react";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";

import { Decorator } from "../Decorator";
import { HFSLogo } from "../HFSLogo";
import { Link } from "../Link";

import Meta from "./_Meta";
import KeyLines from "./_KeyLines";
import SocialLinks from "./_SocialLinks";

export function Footer() {
  const data = useStaticQuery(FOOTER_QUERY);
  const footer = data?.contentfulFooter;

  return (
    <>
      <footer data-component="Footer" id="footer" className="mt-auto">
        <section className="bg-lightBlue">
          <div className="relative pt-16 pb-14 md:pb-32 wrapper default-grid md:pt-14 gap-y-16">
            {footer.decorator && (
              <Decorator
                className="absolute w-64 h-64 translate-x-1/2 translate-y-1/2 bottom-14 md:bottom-7 right-7 md:right-14"
                asset={footer.decorator.asset}
                size="small"
              />
            )}
            <div className="col-span-full md:col-span-2">
              <div className="mx-auto" style={{ maxWidth: 160 }}>
                <GatsbyLink to="/">
                  <div className="relative" style={{ marginTop: "-20%" }}>
                    <div
                      style={{
                        paddingBottom: Math.round((82 / 188) * 100) + "%",
                      }}
                    />
                    <HFSLogo
                      className="absolute top-0 left-0 w-full h-full text-darkPurple"
                      titleId="hfs-logo_footer_01"
                    />
                  </div>
                </GatsbyLink>
              </div>
            </div>
            <div className="col-span-full md:col-span-10">
              <ul className="grid grid-cols-6 lg:grid-cols-12 gap-x-4 gap-y-8 lg:gap-x-14 lg:gap-y-14">
                {footer?.navigationLists?.map(({ links, title }, index) => (
                  <li
                    className="col-span-3 space-y-4"
                    key={`footerNavList-${index}`}
                  >
                    <p className="cap-subtitle text-darkNavy">{title}</p>
                    <ul className="space-y-1">
                      {links?.map(({ label, link }, itemIndex) => (
                        <li key={`footerNavList-${index}_item-${itemIndex}`}>
                          <Link
                            href={link?.link}
                            className="font-bold duration-200 ease-out outline-none body hover:text-violet focus:outline-none focus:text-violet"
                          >
                            {label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
                <li className="hidden col-span-3 md:block">
                  <SocialLinks
                    linkedIn={footer?.linkedIn}
                    instagram={footer?.instagram}
                  />
                </li>
              </ul>
              <div className="block pt-24 md:hidden">
                <SocialLinks
                  linkedIn={footer?.linkedIn}
                  instagram={footer?.instagram}
                />
              </div>
            </div>
          </div>
          <KeyLines />
        </section>
        <Meta
          copyright={footer?.copyright}
          secondaryLinks={footer?.secondaryLinks}
        />
      </footer>
    </>
  );
}

const FOOTER_QUERY = graphql`
  query Footer {
    contentfulFooter {
      navigationLists {
        title
        links {
          ...LinkFragment
        }
      }
      copyright
      secondaryLinks {
        ...LinkFragment
      }
      instagram {
        instagram
      }
      linkedIn {
        linkedIn
      }
      decorator {
        ...DecoratorFragment
      }
    }
  }
`;
